const LegalNotice = (
    <div>
        <p>
            The Site urd-digital.com offers an online viewing and consultation service for the Universal Registration
            Document called Digital URD (hereinafter the "Service").
        </p>
        <p>
            Access to, consultation and use of the Service are subject to full acceptance of the Terms of Service and
            the Service&rsquo;s Privacy Policy.
        </p>

        <h3>
            <strong>Site Editor &ndash; Hosts</strong>
        </h3>
        <p className="!mb-0">The publisher of the website is the Labrador Group (hereinafter "Labrador"):</p>
        <ul>
            <li>SAS with share capital of &euro;100,000;</li>
            <li>Registered office: 8, rue Lantiez, 75017 &ndash; Paris;</li>
            <li>Registered in the Paris Trade and Companies Register under number 3 84362182;</li>
            <li>Intracommunity VAT number: FR28384362182.</li>
        </ul>
        <p>The Website Publication Director is M. Laurent Rouyr&egrave;s</p>
        <p>The website is hosted on the company&rsquo;s servers by the company.</p>
        <p>
            <strong>
                Cyllene
                <br />
                92, rue R&eacute;aumur
                <br />
                75002 Paris
                <br />
            </strong>
            <a href="https://www.google.com/url?q=https://www.groupe-cyllene.com/&amp;sa=D&amp;source=hangouts&amp;ust=1619522237924000&amp;usg=AFQjCNHD_zMYYfI6oSCn-tPRsDoNsgbuDQ">
                https://www.groupe-cyllene.com/
            </a>
        </p>
        <h3>
            <strong>Design &ndash; Referencing &ndash; Maintenance</strong>
        </h3>
        <p>
            Labrador Company
            <br />
            8 rue Lantiez - 75017 Paris &ndash; France
            <br />
            <a href="http://www.labrador-company.com/">www.labrador-company.com</a>
            <br />
        </p>
        <h3>
            <strong>Disclaimer</strong>
        </h3>
        <p>
            The information contained in this Service does not in any way constitute commercial offers of support or
            services and cannot have a contractual value.
        </p>
        <p>
            The publisher of the site may not be held liable for the content of the pages, which are disseminated for
            information purposes only. urd-digital.com
        </p>
        <p>
            The Service is accessible to all Internet users 24 hours a day, seven days a week, except for scheduled or
            unscheduled interruptions by Labrador or its service providers for maintenance and/or security purposes, or
            in the event of force majeure.
        </p>
        <p>
            Labrador cannot be held liable for any damage of any kind resulting from the unavailability of the Service.
        </p>
        <p>
            Labrador does not guarantee that the Service will be free of anomalies, errors or bugs, nor that the Service
            will operate without failure or interruption. In this regard, Labrador may freely and at its sole discretion
            determine any period of unavailability of the Service.
        </p>
        <p>Labrador cannot be held liable for data transmission, connection or network unavailability problems.</p>
        <p>
            Labrador reserves the right to change the Service for technical or commercial reasons. If these changes do
            not substantially and adversely alter the conditions of the provision of the services, the User is informed
            of the changes made, without his or her acceptance being required.
        </p>
        <p className="!mb-0">Labrador declines all responsibility:</p>
        <ul>
            <li>
                In the event of a temporary lack of access to the Service due to maintenance or technical problems,
                regardless of the origin and cause, or the updating of the available documentation;
            </li>
            <li>
                In the event of direct or indirect damage caused to the User, whatever its nature, resulting from the
                content, access, or use of the Service (and/or the Site linked to it). In the event of abnormal use or
                unlawful exploitation of the Service, the User alone is liable for any damage caused to third parties
                and the consequences of any claims or actions that may result. In this respect, the User waives any
                recourse against Labrador in the context of legal proceedings instituted by a third party against them
                for the unlawful use and/or exploitation of the Service.
            </li>
        </ul>
        <p>
            Users browsing the Service and/or completing the contact forms are invited to refer to the Service&rsquo;s
            Privacy Policy.
        </p>
    </div>
);

export default LegalNotice;
