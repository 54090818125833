import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { DialogTrigger, ModalOverlay, Modal, Dialog } from "react-aria-components";
import Button from "~/ui/Button/Button";

interface DocumentProps {
    title?: string | null;
    content?: ReactNode | string;
    closeFn: () => void;
}

const DocumentModal = (props: DocumentProps) => {
    const { title, content, closeFn } = props;
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(true);

    const closeModal = () => {
        setIsOpen(false);
        closeFn();
    };

    return (
        <DialogTrigger key="dialog-trigger" isOpen={isOpen}>
            <ModalOverlay className="modal-overlay">
                <Modal className="modal" isDismissable>
                    <Dialog className="w-full h-full">
                        {() => (
                            <div className="w-full h-full flex flex-col">
                                <Button
                                    color="light"
                                    icon="close"
                                    size="md"
                                    elevated
                                    className="absolute left-full top-8 translate-x-3 -translate-y-1/2"
                                    onPress={() => closeModal()}
                                />
                                {title && <h2 className="modal-title">{t(title)}</h2>}
                                <div className="modal-content">{content}</div>
                            </div>
                        )}
                    </Dialog>
                </Modal>
            </ModalOverlay>
        </DialogTrigger>
    );
};

export default DocumentModal;
