const TermsAndConditions = (
    <div>
        <p>
            The Labrador Group (hereinafter, "<em>Labrador</em>") provides its Users with the Company Universal
            Registration Document online service Digital URD (hereinafter, the "<em>Service</em>"). It offers them the
            possibility to consult this document but also to open an online account in order to access other
            functionalities such as annotation or adding comments.
        </p>
        <p>
            Labrador indicates in this document (hereinafter, the "
            <strong>
                <em>Terms of Service</em>
            </strong>
            ") the terms of use of the proposed Service.
        </p>
        <p>
            Labrador pays particular attention to the respectful processing of its Users&rsquo; Personal Data and
            complies with Regulation (EU) 2016/679 (known as the "GDPR"), as well as with French Law 2018-493 of June
            20, 2018 concerning the protection of Personal Data, which amended Law 78-17 of January 6, 1978 relating to
            data processing, files and freedoms (hereinafter, taken together, the "
            <strong>
                <em>Regulations</em>
            </strong>
            ").
        </p>
        <p>
            The Terms of Service may be modified by Labrador for the purposes of updates in accordance with changes in
            the Regulations, subject to prior notice to its users. In case of doubt, the User is invited to consult
            their latest version available via the Service.
        </p>
        <h4>
            <strong>ARTICLE 1</strong>&nbsp;&nbsp;&nbsp; <strong>DEFINITIONS</strong>
        </h4>
        <p>
            The words defined below, beginning with a capital letter, taken either in the singular or in the plural,
            shall have the following meaning:
        </p>
        <p>
            "
            <strong>
                <em>Service</em>
            </strong>
            ": Refers to the online consultation service offered by Labrador for the Universal Registration Document
            (URD) of any Company that has subscribed to it, called Digital URD. It is deployed on the Company&rsquo;s
            website, which is accessible on all browsers.
        </p>
        <p>
            "
            <strong>
                <em>Data</em>
            </strong>
            ": Means any information relating to an identified or identifiable natural person, that is to say who can be
            identified, directly or indirectly by reference to an identifier, such as a name, an identification number,
            location data, an online identifier, or one or more elements specific to their physical, physiological,
            genetic, psychological, economic, cultural or social identity.
        </p>
        <p>
            "
            <strong>
                <em>Account</em>
            </strong>
            ": Means the personal account created by the User of the Service by means of a connection to the Service
            allowing him or her to access certain functionalities linked to the consultation of the Universal
            Registration Document.
        </p>
        <p>
            "
            <strong>
                <em>Universal Registration Document</em>
            </strong>
            " or "
            <strong>
                <em>URD</em>
            </strong>
            &rdquo;: Refers to the registration document updated each year that listed companies are required to prepare
            and file with the French Financial Markets Authority (AMF).
        </p>
        <p>
            "
            <strong>
                <em>User</em>
            </strong>
            ": Means any natural person who uses the Service offered by Labrador, consults the Universal Registration
            Document, or even creates an account and accepts the Terms of Service.
        </p>
        <h4>
            <strong>ARTICLE 2</strong>&nbsp;&nbsp;&nbsp;{" "}
            <strong>REGISTRATION TO THE SERVICE AND DATA COLLECTION</strong>
        </h4>
        <p>
            The use of the online Service is not subject to the creation of an Account. Indeed, access to the URD in
            question is public and the document can be consulted at any time. The User logs on to the Service, reads the
            Terms of Service and accepts them.
        </p>
        <p>
            However, registration to the Service, allowing access to other functionalities, is carried out by creating
            an online account.
        </p>
        <p className="!mb-0">To do so, the User is asked to provide the following compulsory data:</p>
        <ul>
            <li>First name;</li>
            <li>Family name;</li>
            <li>Email address.</li>
        </ul>
        <p>
            Once their Account is created, a confirmation email of registration is automatically generated and sent to
            the address provided, including these Terms of Service.
        </p>
        <p>
            The Service allows the User to annotate the document or leave comments and save them in order to consult
            them as soon as he or she logs on.
        </p>
        <p>
            The User is informed that Labrador does not collect the content of his or her comments or annotations but
            only their location in the document.
        </p>
        <p>
            The User undertakes to communicate, at the time of registration, accurate, complete and up-to-date Data,
            excluding any statement contrary to the legislation in force, public order and morality, not usurping the
            identity of a third party or infringing the intellectual property rights of a third party.
        </p>
        <p>
            In addition, the User may refuse to communicate the requested Data, but is duly informed that he or she will
            not be able to benefit from all the functionalities of the Service in this case.
        </p>
        <p>
            The User is informed that so-called "cookies" tracking technologies are also placed on Users&rsquo; devices
            when they are browsing using the Service. The Data collected through these cookies and their management
            methods are described in more detail in Article 14 of this document.
        </p>
        <h4>
            <strong>ARTICLE 3</strong>&nbsp;&nbsp;&nbsp; <strong>DESCRIPTION OF THE APPLICATION FUNCTIONALITIES</strong>
        </h4>
        <p>The Service offers the following functionalities:</p>
        <table border={1} cellSpacing="0" cellPadding="0">
            <thead>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "147.5pt" }} valign="top">
                        <p>
                            <strong>
                                <em>Functionalities:</em>
                            </strong>
                        </p>
                    </td>
                    <td style={{ width: "303.25pt" }} valign="top">
                        <p>
                            <strong>
                                <em>Making it possible to:</em>
                            </strong>
                        </p>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "147.5pt" }} valign="top">
                        <p>Consult the URD</p>
                    </td>
                    <td style={{ width: "303.25pt" }} valign="top">
                        <p>
                            Propose to each User the consultation of the URD of the Company that has subscribed to the
                            Digital URD.
                        </p>
                    </td>
                </tr>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "147.5pt" }} valign="top">
                        <p>Annotate or comment on the URD</p>
                    </td>
                    <td style={{ width: "303.25pt" }} valign="top">
                        <p>
                            It is up to the User to make any remarks or underline any important information relating to
                            the document he or she is viewing.
                        </p>
                        <p>Consult at any time the notes or comments made during the review of said document.</p>
                    </td>
                </tr>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "147.5pt" }} valign="top">
                        <p>Contact the Company for which the URD was published</p>
                    </td>
                    <td style={{ width: "303.25pt" }} valign="top">
                        <p>
                            The Service provides Users with a contact form to fill in for any type of request that is
                            forwarded to the Company concerned so that it can respond and get back to the User as soon
                            as possible.
                        </p>
                        <p>&nbsp;</p>
                    </td>
                </tr>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "147.5pt" }} valign="top">
                        <p>Receive the URD in hard copy</p>
                    </td>
                    <td style={{ width: "303.25pt" }} valign="top">
                        <p>It is up to the User to receive the hard copy version of the URD by post.</p>
                    </td>
                </tr>
            </tbody>
        </table>
        <h4>
            <strong>ARTICLE 4</strong>&nbsp;&nbsp;&nbsp; <strong>USER OBLIGATIONS</strong>
        </h4>
        <p>
            The User undertakes to use the functionalities of the Service in accordance with their specific purpose and
            to not circumvent said purposes through inappropriate use.
        </p>
        <p>
            The User also expressly undertakes not to (i) transmit a computer virus, worm, trojan horse, logic bomb or
            any other malicious file, program or form of code, intended to damage, interrupt, suspend, destroy and/or
            limit the functionality of any IT or telecommunication equipment, (ii) impersonate another person, User or
            employee or representative of Labrador, (iii) use in-depth data mining tools, robots or any other similar
            tool for collecting and extracting data relating to the Service, (iv) interfere with the operation of the
            Service, servers or network connections of the Service, (v) violate any requirements, procedures, policies
            or regulations relating to networks connected to the Service, (vi) modify, adapt, sub-license, translate,
            sell, reverse engineer, decompile or disassemble all or part of the Service, (vii) attempt to access the
            Service without authorization, (viii) and/or use the Service to send or transmit any content that could harm
            or disparage Labrador or the Company for which the URD was published in any way and (ix) otherwise infringe
            the rights of third parties or the laws and regulations in force.
        </p>
        <p>
            In the event of a breach by the User of any one of its obligations stipulated above, Labrador reserves the
            right to suspend or terminate the User&rsquo;s Account under the provisions of Article 6 of this document.
        </p>
        <h4>
            <strong>ARTICLE 5</strong>&nbsp;&nbsp;&nbsp; <strong>INTELLECTUAL PROPERTY</strong>
        </h4>
        <p>
            The Service and all the elements of which it consists, such as software, codes, databases, texts,
            documentation, designs, logos, images, graphics, or any other content (hereinafter, the "
            <strong>
                <em>Elements</em>
            </strong>
            " are the exclusive property of Labrador or of the Company for which the URD is published. The Elements are
            protected by French and international legislation on intellectual property rights (copyright and trademark
            law in particular) and may contain trade secrets or protected and confidential information. The User hereby
            agrees not to remove and/or delete any notices relating to copyright and any other intellectual property
            rights appearing on the Service.
        </p>
        <p>
            It is agreed that these Terms of Service do not grant any rights to the User over trademarks, copyrights or
            designs and models owned by Labrador and its licensors and partners, nor over any Element other than the
            license hereinafter granted.
        </p>
        <p>
            Subject to compliance with the provisions of this document, the User is only authorized to access and use
            the Service for his or her own needs and is granted a limited, non-exclusive and non-transferable right of
            access and use that may not be subject to a sub-license, and allows the User to display all or part of the
            functionalities of the Service, for the entire duration of their Account.
        </p>
        <p>
            The User is and remains the owner of, or at least responsible for, all the Data and content that he or she
            makes available, transmits or stores on their<em> </em>smartphone via the Service and within the framework
            of this document (Personal Data, photographs, etc.).
        </p>
        <h4>
            <strong>ARTICLE 6</strong>&nbsp;&nbsp;&nbsp; <strong>DURATION OF REGISTRATION AND TERMINATION</strong>
        </h4>
        <p>Registration to the Service is for an indefinite period.</p>
        <p>
            The User may terminate their Account on the Service at any time by going to their personal space and
            clicking on the link "Delete my account".[1] [2]&nbsp;&nbsp; If the User has closed his or her Account, they
            will no longer be able to access the notes or comments they have made on the URD and are informed that the
            Data previously uploaded through their Account (including their notes) will be kept for a period of one
            year.
        </p>
        <p>
            In the event of a breach by the User of any of their obligations under these Terms of Service, Labrador will
            send a notification to the User by email giving notice to remedy the breach. If the notification remains
            without response for a period of eight (8) calendar days or if the User commits a new breach within this
            period, Labrador reserves the right to suspend their access to the Account or to immediately and
            automatically terminate their Account by notification sent by email, without prejudice to any other rights
            and remedies.
        </p>
        <p>
            In the event of termination, by the User or Labrador, in accordance with this article, the User will no
            longer be able to access the functionalities of the Service and their Account, which will be closed.
        </p>
        <p>
            All warranty and liability limitations provided for in these Terms of Service shall remain in effect after
            termination.
        </p>
        <h4>
            <strong>ARTICLE 7</strong>&nbsp;&nbsp;&nbsp; <strong>APPLICABLE LAW AND DISPUTE RESOLUTION</strong>
        </h4>
        <p>
            If one of the provisions of the Terms of Service proves to be null, non-enforceable, contrary to the law or
            not applicable for any reason, it is agreed that the disputed clause may not alter the other provisions
            which retain their full effect.
        </p>
        <p>
            Any difficulty relating to the execution, interpretation or validity of these Terms of Service, which cannot
            first be settled amicably with Labrador, is subject to French law and to the competent courts under the
            terms of common law and the French Code of Civil Procedure. For any complaint relating to the management of
            their Data, the User may contact the CNIL. To find out about the management and Privacy Policy for their
            Personal Data, the Service User is invited to consult the following page:{" "}
            <strong>personaldataprotection.bureauveritas.com/home/1.1.5/#/privacypolicy</strong>.
        </p>
        <h4>
            <strong>ARTICLE 8</strong>&nbsp;&nbsp;&nbsp; <strong>CONTACT LABRADOR!</strong>
        </h4>
        <p className="!mb-0">
            If you have any questions regarding the management of your Data or the terms and conditions of use of the
            Service, please contact Labrador:
        </p>
        <ul>
            <li>
                By email, at: <a href="mailto:contact@labrador-company.com">contact@labrador-company.com</a>;
            </li>
            <li>By mail: Labrador, 8 rue Lantiez, 75017 Paris;</li>
            <li>By telephone at: 00 33 (0)153 063080.</li>
        </ul>
        <p>All questions are welcome!</p>
        <br />
        <br />
        <p>
            <strong>Latest version of the Terms of Service: April 26, 2021</strong>
        </p>
    </div>
);

export default TermsAndConditions;
