const PrivacyPolicyFR = (
    <div>
        <h3 style={{ marginTop: 0 }}>
            <strong>POLITIQUE DE CONFIDENTIALIT&Eacute; &ndash; DONN&Eacute;EES PERSONNELLES</strong>
        </h3>
        <p>
            Dans le cadre de son service de mise &agrave; disposition en ligne du Document d&rsquo;enregistrement
            universel &ndash; URD Digital &ndash; le Groupe Labrador (ci-apr&egrave;s &laquo; Labrador &raquo;) est
            amen&eacute; &agrave; traiter des informations vous concernant. La pr&eacute;sente s&rsquo;applique
            notamment lorsque vous acc&eacute;dez au Service, en consultant le Document d&rsquo;enregistrement
            universel, en cr&eacute;ant un compte ou lorsque vous remplissez un formulaire pour obtenir le document en
            version papier ou pour nous contacter, vous nous transmettez des informations dont certaines sont de nature
            &agrave; vous identifier (ci-apr&egrave;s &laquo; Donn&eacute;es Personnelles &raquo;).
        </p>
        <p>
            Labrador respecte votre vie priv&eacute;e et s&rsquo;engage &agrave; prot&eacute;ger vos donn&eacute;es
            personnelles. Cette politique de confidentialit&eacute; a pour objectif de vous informer de la
            mani&egrave;re dont vos donn&eacute;es personnelles sont trait&eacute;es, de leur &eacute;ventuel transfert
            aupr&egrave;s de tiers ainsi que de vos droits et des options dont vous disposez pour contr&ocirc;ler vos
            informations personnelles et prot&eacute;ger votre vie priv&eacute;e.
        </p>
        <p>Nous vous invitons &agrave; lire cette politique de confidentialit&eacute; attentivement.</p>
        <p>
            <strong>Que trouverez-vous dans cette politique de confidentialit&eacute; ?</strong>
        </p>
        <h3>
            <strong>Qu&rsquo;est-ce qu&rsquo;une Donn&eacute;e Personnelle ? </strong>
        </h3>
        <p>
            Les Donn&eacute;es Personnelles d&eacute;signent toute information susceptible de vous identifier
            directement (par ex., vos nom et pr&eacute;nom ou adresse email) ou indirectement (par ex., par le biais
            d&rsquo;un identifiant unique).&nbsp;
        </p>
        <p>
            Cela signifie que les Donn&eacute;es Personnelles incluent des informations telles que des adresses de
            messagerie, num&eacute;ro de t&eacute;l&eacute;phone portable, noms d&rsquo;utilisateur,
            pr&eacute;f&eacute;rences personnelles, des contenus g&eacute;n&eacute;r&eacute;s par des utilisateurs, des
            donn&eacute;es financi&egrave;res et des informations relatives &agrave; votre sant&eacute;. Les
            Donn&eacute;es Personnelles sont &eacute;galement susceptibles d&rsquo;inclure des identifiants
            num&eacute;riques uniques comme l&rsquo;adresse IP de votre ordinateur ou l&rsquo;adresse MAC de votre
            appareil mobile, ainsi que des cookies.
        </p>
        <h3>
            <strong>Qui est le responsable de traitement de vos Donn&eacute;es Personnelles ?</strong>
        </h3>
        <p>
            Labrador, soci&eacute;t&eacute; par action simplifi&eacute;e sise 8 rue Lantiez, 75017 est le responsable de
            traitement des donn&eacute;es personnelles collect&eacute;es via le service URD Digital, ci-apr&egrave;s
            &laquo; le Service &raquo; au sens de la r&eacute;glementation applicable en mati&egrave;re de
            donn&eacute;es personnelles et notamment du R&egrave;glement UE 2016/679 relatif &agrave; la protection des
            personnes physiques &agrave; l&rsquo;&eacute;gard du traitement des Donn&eacute;es Personnelles et &agrave;
            la libre circulation de ces donn&eacute;es (ci-apr&egrave;s &laquo; RGPD &raquo;).
        </p>
        <h3>
            <strong>
                Comment sont collect&eacute;es vos Donn&eacute;es Personnelles et &agrave; quelles fins ?&nbsp;{" "}
            </strong>
        </h3>
        <p>
            Pour Labrador, d&eacute;livrer une information d&rsquo;entreprise claire, coh&eacute;rente et performante
            est au c&oelig;ur de tout ce que nous entreprenons. Nous mettons donc &agrave; votre disposition un Service
            accessible &agrave; tous, dont certaines fonctionnalit&eacute;s sont conditionn&eacute;es &agrave; la
            cr&eacute;ation d&rsquo;un compte, nous permettant de recevoir des informations de votre part et
            &eacute;changer avec vous afin de vous proposer un contenu adapt&eacute; mais &eacute;galement vous fournir
            l&rsquo;assistance n&eacute;cessaire lors de votre consultation des documents utiles. Pour toutes ces
            raisons, il existe de nombreuses mani&egrave;res dont vous pouvez nous communiquer vos Donn&eacute;es
            Personnelles, et dont nous pouvons les collecter.
        </p>
        <p>
            Nous pouvons collecter des Donn&eacute;es Personnelles aupr&egrave;s de vous, ou en recevoir de votre part,
            via notre Service et pages consacr&eacute;es &agrave; URD Digital.
        </p>
        <p>
            Dans certains cas, vous nous communiquez des Donn&eacute;es Personnelles directement (par ex., lorsque vous
            cr&eacute;ez un compte sur le Service, lorsque vnous souhaitez obtenir le Document d&rsquo;enregistrement
            universel au format papier ou lorsque vous nous contactez via le formulaire aff&eacute;rent).&nbsp;
        </p>
        <p>
            Dans d&rsquo;autres cas, nous proc&eacute;dons &agrave; la collecte de ces Donn&eacute;es Personnelles de
            mani&egrave;re indirecte (par ex., via les cookies et autres traceurs d&eacute;pos&eacute;s lors de
            l&rsquo;utilisation du Service).
        </p>
        <p>
            Vous trouverez dans le tableau ci-dessous des informations plus d&eacute;taill&eacute;es apportant des
            explications sur les &eacute;l&eacute;ments suivants :
        </p>
        <ul>
            <li>
                <strong>
                    Dans quelles situations vos Donn&eacute;es Personnelles peuvent-elles &ecirc;tre fournies ou
                    collect&eacute;es ?{" "}
                </strong>
                <p>
                    Cette colonne r&eacute;pertorie les activit&eacute;s auxquelles vous vous livrez, ou les situations
                    dans lesquelles vous vous trouvez, lorsque nous utilisons ou collectons vos Donn&eacute;es
                    Personnelles. Par exemple, si vous cr&eacute;ez un compte en ligne, lorsque vous d&eacute;posez des
                    commentaires ou notes sur le Document d&rsquo;enregistrement universel ou lorsque vous effectuez une
                    demande de contact ou si vous naviguez sur une des pages du site Internet.
                </p>
            </li>
            <li>
                <strong>
                    Quelles Donn&eacute;es Personnelles pouvons-nous obtenir directement de votre part ou suite &agrave;
                    votre interaction avec nous ?
                </strong>
                <p>
                    Cette colonne indique quels types de donn&eacute;es vous concernant nous sommes susceptibles de
                    collecter selon la situation.
                </p>
            </li>
            <li>
                <strong>Comment et pourquoi pouvons-nous les utiliser ?</strong>
                <p>
                    Cette colonne explique ce que nous pouvons faire avec vos donn&eacute;es, et pour quelles
                    finalit&eacute;s nous les collectons.
                </p>
            </li>
            <li>
                <strong>
                    Sur quelle base juridique repose notre utilisation de vos Donn&eacute;es Personnelles ?{" "}
                </strong>
                <p className="!mb-0">
                    Cette colonne explique la raison pour laquelle nous sommes susceptibles d&rsquo;utiliser vos
                    Donn&eacute;es Personnelles. Selon la finalit&eacute; pour laquelle les donn&eacute;es sont
                    utilis&eacute;es par Labrador, la base juridique sur laquelle repose le traitement de vos
                    donn&eacute;es peut &ecirc;tre :
                </p>
                <ul>
                    <li>Votre consentement ;</li>
                    <li>
                        Des obligations l&eacute;gales d&egrave;s lors que la l&eacute;gislation en vigueur impose le
                        traitement des donn&eacute;es.&nbsp;&nbsp;
                    </li>
                </ul>
            </li>
        </ul>

        <p>
            Aper&ccedil;u des informations relatives &agrave; vos interactions avec nous et leurs cons&eacute;quences
            sur vos Donn&eacute;es Personnelles :&nbsp;
        </p>
        <table border={0} cellSpacing="0" cellPadding="0">
            <thead>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "116.8pt" }} valign="top">
                        <p>
                            <strong>
                                Dans quelles situations vos Donn&eacute;es Personnelles peuvent &ecirc;tre fournies ou
                                collect&eacute;es ?
                            </strong>
                        </p>
                    </td>
                    <td style={{ width: "116.05pt" }} valign="top">
                        <p>
                            <strong>
                                Quelles Donn&eacute;es Personnelles pouvons-nous obtenir directement de votre part ou
                                suite &agrave; votre interaction avec nous ?
                            </strong>
                        </p>
                    </td>
                    <td style={{ width: "131.8pt" }} valign="top">
                        <p>
                            <strong>Comment et pourquoi pouvons-nous les utiliser ?</strong>
                        </p>
                    </td>
                    <td style={{ width: "86.1pt" }} valign="top">
                        <p>
                            <strong>
                                Sur quelle base juridique repose le traitement de vos Donn&eacute;es Personnelles ?
                            </strong>
                        </p>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "116.8pt" }} valign="top">
                        <p>
                            <strong>La navigation via le Service</strong>
                        </p>
                        <p>
                            Donn&eacute;es collect&eacute;es par des cookies* lors de votre navigation via le Service
                            (ci-apr&egrave;s Cookies).
                        </p>
                        <p>
                            &nbsp;
                            <br />
                            Pour toute information sur des Cookies sp&eacute;cifiques plac&eacute;s par le biais du
                            Service, veuillez consulter le tableau relatif aux cookies figurant sur en bas de page du
                            site Internet.
                            <br />
                            <br />* Les cookies sont des petits fichiers textes qui sont enregistr&eacute;s sur votre
                            appareil (ordinateur, tablette ou mobile) lorsque vous utilisez le Service URD Digital.
                        </p>
                    </td>
                    <td style={{ width: "116.05pt" }} valign="top">
                        <ul>
                            <li>Donn&eacute;es de connexion ;</li>
                            <li>Donn&eacute;es relatives &agrave; l&rsquo;utilisation du Service ;</li>
                            <li>Nombre de pages consult&eacute;es par l&rsquo;Utilisateur ;</li>
                            <li>Noms des pages consult&eacute;es par l&rsquo;Utilisateur ;</li>
                            <li>Cheminement de l&rsquo;Utilisateur ;</li>
                            <li>Dernier site visit&eacute; ;</li>
                            <li>Dur&eacute;e de la visite ;</li>
                            <li>Adresse IP (date, pays, r&eacute;gion) ;</li>
                            <li>Donn&eacute;es relatives au navigateur ;</li>
                            <li>Donn&eacute;es relatives &agrave; l&rsquo;appareil.</li>
                        </ul>
                    </td>
                    <td style={{ width: "131.8pt" }} valign="top">
                        <ul>
                            <li>Assurer le bon fonctionnement et la s&eacute;curit&eacute; du Service ;</li>
                            <li>
                                &Eacute;laborer des statistiques permettant l&rsquo;analyse de fr&eacute;quentation du
                                Service.
                            </li>
                        </ul>
                    </td>
                    <td style={{ width: "86.1pt" }} valign="top">
                        <p>
                            <strong>Consentement</strong>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            La validation du bandeau Cookie pr&eacute;sent lors de l&rsquo;acc&egrave;s au Service
                            permet le recueil du consentement de l&rsquo;Utilisateur qui est invit&eacute; &agrave; se
                            reporter &agrave; la section &laquo; <em>Gestion des cookies</em> &raquo;.
                        </p>
                    </td>
                </tr>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "116.8pt" }} valign="top">
                        <p>
                            <strong>Cr&eacute;ation et gestion d&rsquo;un compte</strong>
                        </p>
                        <p>
                            Donn&eacute;es collect&eacute;es lors de la cr&eacute;ation d&rsquo;un compte sur le Service
                            au moyen d&rsquo;une connexion
                        </p>
                    </td>
                    <td style={{ width: "116.05pt" }} valign="top">
                        <ul>
                            <li>Nom et pr&eacute;nom ;</li>
                            <li>Adresse de messagerie ;</li>
                            <li>Mot de passe ;</li>
                        </ul>
                    </td>
                    <td style={{ width: "131.8pt" }} valign="top">
                        <ul>
                            <li>
                                Permettre aux Utilisateurs du Service de d&eacute;poser des notes ou commentaires sur le
                                document qui sont sauvegard&eacute;es et accessibles &agrave; tout moment lors des
                                prochaines consultations.
                            </li>
                            <li>
                                &Eacute;laborer des statistiques selon la localisation des commentaires ou notes
                                &eacute;mises par les Utilisateurs.&nbsp;
                            </li>
                        </ul>
                    </td>
                    <td style={{ width: "86.1pt" }} valign="top">
                        <p>
                            <strong>Consentement</strong>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            La validation de de la mention d&rsquo;information lors de la cr&eacute;ation du Compte
                            permet &agrave; Labrador de recueillir le consentement de l&rsquo;Utilisateur. Il est
                            confirm&eacute; par l&rsquo;envoi d&rsquo;un e-mail automatique rappelant les
                            modalit&eacute;s de traitementtraitements des donn&eacute;es et la possibilit&eacute; de
                            s&rsquo;y opposer.
                        </p>
                    </td>
                </tr>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "116.8pt" }} valign="top">
                        <p>
                            <strong>La gestion des droits des Utilisateurs</strong> (cf. Point 9.)
                        </p>
                    </td>
                    <td style={{ width: "116.05pt" }} valign="top">
                        <ul>
                            <li>Nom, pr&eacute;nom ;</li>
                            <li>Coordonn&eacute;es postales et/ou t&eacute;l&eacute;phoniques ;</li>
                            <li>
                                Justificatif d&rsquo;identit&eacute;,{" "}
                                <strong>
                                    en cas de doute raisonnable sur l&rsquo;identit&eacute; de la personne
                                    concern&eacute;e
                                </strong>
                                .
                            </li>
                        </ul>
                    </td>
                    <td style={{ width: "131.8pt" }} valign="top">
                        <ul>
                            <li>
                                Permettre &agrave; Labrador de r&eacute;pondre aux demandes d&rsquo;exercice des droits
                                des Utilisateurs.
                            </li>
                        </ul>
                    </td>
                    <td style={{ width: "86.1pt" }} valign="top">
                        <p>
                            En exer&ccedil;ant vos droits tels que d&eacute;finis au Point 9., Labrador traite vos
                            Donn&eacute;es afin de se conformer &agrave; ses obligations l&eacute;gales ou
                            r&eacute;glementaires mais &eacute;galement &agrave; son int&eacute;r&ecirc;t
                            l&eacute;gitime notamment pour la pr&eacute;vention des contentieux.
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
        <p>
            <em>
                Les Donn&eacute;es marqu&eacute;es d&rsquo;un (*) dans les formulaires disponibles via le Service sont
                obligatoires pour le traitement des diff&eacute;rentes demandes formul&eacute;es par les Utilisateurs.
                Dans le cas o&ugrave; l&rsquo;Utilisateur ne souhaiterait pas communiquer ces Donn&eacute;es, il est
                inform&eacute; que sa demande ne pourra &ecirc;tre trait&eacute;e.{" "}
            </em>
        </p>
        <p>
            <em>
                L&rsquo;Utilisateur s&rsquo;engage &agrave; ne fournir que des donn&eacute;es sinc&egrave;res, exactes,
                compl&egrave;tes et mises &agrave; jour et &agrave; ne pas usurper celles d&rsquo;un tiers.&nbsp;{" "}
            </em>
        </p>

        <h3>
            <strong>Combien de temps Labrador conserve-t-il les donn&eacute;es collect&eacute;es ? </strong>
        </h3>
        <p>
            Labrador informe l&rsquo;Utilisateur qu&rsquo;il conserve ses Donn&eacute;es pour une dur&eacute;e qui
            n&rsquo;exc&egrave;de pas celle strictement n&eacute;cessaire au regard des finalit&eacute;s pour lesquelles
            elles ont &eacute;t&eacute; trait&eacute;es susvis&eacute;es, &agrave; savoir :
        </p>
        <ul>
            <li>
                Pour les Donn&eacute;es collect&eacute;es lors de la navigation via le Service : les Donn&eacute;es
                dites &laquo; cookies &raquo; sont conserv&eacute;es pour une dur&eacute;e qui n&rsquo;exc&egrave;de pas
                treize (13) mois &agrave; compter de leur placement sur le terminal de l&rsquo;Utilisateur ;
            </li>
            <li>
                Pour les Donn&eacute;es collect&eacute;es suite &agrave; la cr&eacute;ation d&rsquo;un compte en ligne :
                les Donn&eacute;es sont conserv&eacute;es trois (ans) &agrave; compter de la suppression de votre compte
                ou &agrave; l&rsquo;issue d&rsquo;une p&eacute;riode d&rsquo;inactivit&eacute; (aucune connexion au
                compte) ;
            </li>
            <li>
                Pour les Donn&eacute;es collect&eacute;es &agrave; des fins d&rsquo;analyses statistiques,
                l&rsquo;adresse IP de l&rsquo;Utilisateur est conserv&eacute;e 1 an &agrave; partir de sa
                derni&egrave;re connexion ;
            </li>
            <li>
                Pour les Donn&eacute;es collect&eacute;es suite &agrave; une demande d&rsquo;exercice de droits (cf.
                Point 7.) : les Donn&eacute;es sont conserv&eacute;es un (1) an en cas d&rsquo;exercice du droit
                d&rsquo;acc&egrave;s ou de rectification &agrave; compter de la r&eacute;ception de la demande, et trois
                (3) ans en cas d&rsquo;exercice du droit d&rsquo;opposition &agrave; compter de la r&eacute;ception de
                la demande.
            </li>
        </ul>
        <p>
            Lorsque leur conservation n&rsquo;est plus justifi&eacute;e par des exigences l&eacute;gales ou si vous avez
            fait usage d&rsquo;un droit de modification ou d&rsquo;effacement, nous supprimerons les Donn&eacute;es de
            fa&ccedil;on s&eacute;curis&eacute;e.
        </p>
        <h3>
            <strong>Qui sont les destinataires des donn&eacute;es collect&eacute;es ? </strong>
        </h3>
        <p>
            Les donn&eacute;es collect&eacute;es via le Service URD Digital sont destin&eacute;es &agrave; Labrador.
            Elles sont transmises &agrave; la soci&eacute;t&eacute; pour laquelle le Document d&rsquo;enregistrement
            universel est &eacute;dit&eacute; mais &eacute;galement aux prestataires et sous-traitants auxquels Labrador
            peut faire appel dans le cadre de la gestion et la garantie du bon fonctionnement du Service propos&eacute;,
            de l&rsquo;h&eacute;bergement, des services de maintenance ou de support technique pour nos bases de
            donn&eacute;es.&nbsp;
        </p>
        <p>
            Pour la parfaite information de l&rsquo;Utilisateur, Labrador informe recourir aux sous-traitants suivants :
        </p>
        <table border={0} cellSpacing="0" cellPadding="0">
            <thead>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "77.5pt" }} valign="top">
                        <p>
                            <strong>Cat&eacute;gorie de sous-traitant</strong>
                        </p>
                    </td>
                    <td style={{ width: "122.95pt" }} valign="top">
                        <p>
                            <strong>Situation de transmission des donn&eacute;es</strong>
                        </p>
                    </td>
                    <td style={{ width: "131.15pt" }} valign="top">
                        <p>
                            <strong>Fondement juridique de la transmission des donn&eacute;es</strong>
                        </p>
                    </td>
                    <td style={{ width: "119.2pt" }} valign="top">
                        <p>
                            <strong>Type de donn&eacute;es recueillies</strong>
                        </p>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "77.5pt" }} rowSpan={3} valign="top">
                        <p>
                            Soci&eacute;t&eacute; pour laquelle est &eacute;dit&eacute; le Document
                            d&rsquo;enregistrement universel
                        </p>
                    </td>
                    <td style={{ width: "122.95pt" }} valign="top">
                        <p>
                            <strong>Lorsque l&rsquo;Utilisateur formule une demande de contact, </strong>les
                            informations recueillies via le formulaire aff&eacute;rent sont transmises &agrave; ladite
                            Soci&eacute;t&eacute;, conform&eacute;ment &agrave; sa Politique de Confidentialit&eacute;,
                            dont le lien est joint au formulaire, afin de :
                        </p>
                        <ul>
                            <li>
                                Recontacter l&rsquo;Utilisateur notamment par voie d&rsquo;email ou d'appel
                                t&eacute;l&eacute;phonique ;
                            </li>
                            <li>R&eacute;pondre &agrave; ses demandes de renseignement ;&nbsp;</li>
                            <li>
                                Mettre en relation l&rsquo;Utilisateur avec le service appropri&eacute; si
                                n&eacute;cessaire.
                            </li>
                        </ul>
                    </td>
                    <td style={{ width: "131.15pt" }} valign="top">
                        <p>
                            <strong>Consentement</strong>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            La validation de la mention d&rsquo;information dans le formulaire aff&eacute;rent lors de
                            l&rsquo;envoi de la demande permet &agrave; la soci&eacute;t&eacute; pour laquelle est
                            &eacute;dit&eacute; le Document d&rsquo;enregistrement universel de recueillir le
                            consentement de l&rsquo;Utilisateur. Il est confirm&eacute; par l&rsquo;envoi d&rsquo;un
                            e-mail automatique rappelant les modalit&eacute;s de traitement des donn&eacute;es et la
                            possibilit&eacute; de s&rsquo;y opposer.
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            Pour plus d&rsquo;informations, veuillez consulter sa Politique de Confidentialit&eacute; de
                            l'emetteur en bas de page
                        </p>
                    </td>
                    <td style={{ width: "119.2pt" }} valign="top">
                        <p>
                            La soci&eacute;t&eacute; recueille le nom, le pr&eacute;nom, l&rsquo;adresse e-mail et le
                            num&eacute;ro de t&eacute;l&eacute;phone de l&rsquo;Utilisateur ainsi que le contenu du
                            message envoy&eacute; afin de traiter sa demande<strong>.</strong>
                        </p>
                    </td>
                </tr>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "122.95pt" }} valign="top">
                        <p>
                            <strong>
                                Lorsque l&rsquo;Utilisateur formule une demande de document au format papier,{" "}
                            </strong>
                            les informations recueillies via le formulaire aff&eacute;rent sont transmises &agrave;
                            ladite soci&eacute;t&eacute;, conform&eacute;ment &agrave; sa Politique de
                            Confidentialit&eacute;, dont le lien est joint au formulaire, afin de :
                        </p>
                        <p>
                            -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Proc&eacute;der &agrave; l&rsquo;envoi postal de
                            la documentation demand&eacute;e.
                        </p>
                    </td>
                    <td style={{ width: "131.15pt" }} valign="top">
                        <p>
                            <strong>Consentement</strong>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            La validation de la mention d&rsquo;information dans le formulaire aff&eacute;rent lors de
                            l&rsquo;envoi de la demande permet &agrave; la soci&eacute;t&eacute; pour laquelle est
                            &eacute;dit&eacute;e le Document d&rsquo;enregistrement universel de recueillir le
                            consentement de l&rsquo;Utilisateur. Il est confirm&eacute; par l&rsquo;envoi d&rsquo;un
                            e-mail automatique rappelant les modalit&eacute;s de traitements des donn&eacute;es et la
                            possibilit&eacute; de s&rsquo;y opposer.
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            Pour plus d&rsquo;informations, veuillez consulter sa Politique de Confidentialit&eacute; de
                            l'emetteur en bas de page.
                        </p>
                    </td>
                    <td style={{ width: "119.2pt" }} valign="top">
                        <p>
                            La soci&eacute;t&eacute; recueille le nom, le pr&eacute;nom, l&rsquo;adresse e-mail et
                            l&rsquo;adresse postale de l&rsquo;Utilisateur afin de traiter sa demande<strong>.</strong>
                        </p>
                    </td>
                </tr>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "122.95pt" }} valign="top">
                        <p>
                            <strong>
                                Lorsque l&rsquo;Utilisateur a cr&eacute;&eacute; un compte et annot&eacute;e ou
                                comment&eacute;e le Document d&rsquo;enregistrement universel,{" "}
                            </strong>
                            des informations relatives &agrave; la localisation des annotations ou commentaires de
                            l&rsquo;Utilisateur sont transmises sous forme de statistiques anonymes &agrave; ladite
                            soci&eacute;t&eacute;, &agrave; des fins marketing.
                        </p>
                    </td>
                    <td style={{ width: "131.15pt" }} valign="top">
                        <p>
                            <strong>Le contrat entre Labrador et ladite soci&eacute;t&eacute;</strong>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            Les informations transmises &agrave; la soci&eacute;t&eacute; pour laquelle est
                            &eacute;dit&eacute; le Document d&rsquo;enregistrement universel sont des donn&eacute;es
                            anonymis&eacute;es relatives &agrave; des statistiques de localisation qui ne permettent pas
                            d&rsquo;identifier l&rsquo;Utilisateur. Cette transmission r&eacute;pond &agrave; une
                            obligation contractuelle incombant &agrave; Labrador.
                        </p>
                    </td>
                    <td style={{ width: "119.2pt" }} valign="top">
                        <p>
                            La soci&eacute;t&eacute; recueille uniquement des statistiques de localisation de
                            commentaires ou d&rsquo;annotations effectu&eacute;es sur le Document d&rsquo;enregistrement
                            universel par l&rsquo;Utilisateur.
                        </p>
                    </td>
                </tr>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "77.5pt" }} valign="top">
                        <p>Prestataire de gestion num&eacute;rique des Utilisateurs du Service</p>
                        <p>&nbsp;</p>
                    </td>
                    <td style={{ width: "122.95pt" }} valign="top">
                        <p>
                            <strong>Lorsque l&rsquo;Utilisateur cr&eacute;&eacute; un compte en ligne, </strong>les
                            informations communiqu&eacute;es lors de sa cr&eacute;ation sont int&eacute;gr&eacute;es
                            &agrave; des logiciels d&eacute;di&eacute;s &agrave; la gestion des Utilisateurs du Service
                            apr&egrave;s recueil de leur consentement exprim&eacute; lors de la validation de la
                            pr&eacute;sente Politique de confidentialit&eacute; au moment de la cr&eacute;ation.
                        </p>
                    </td>
                    <td style={{ width: "131.15pt" }} valign="top">
                        <p>
                            <strong>Consentement</strong>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            La validation de la mention d&rsquo;informations renvoyant &agrave; la Politique de
                            Confidentialit&eacute; du Service lors de la cr&eacute;ation du Compte en ligne permet
                            &agrave; Labrador de recueillir le consentement de l&rsquo;Utilisateur. Il est
                            confirm&eacute; par l&rsquo;envoi d&rsquo;un e-mail automatique rappelant les
                            modalit&eacute;s de traitements des donn&eacute;es et la possibilit&eacute; de s&rsquo;y
                            opposer.
                        </p>
                    </td>
                    <td style={{ width: "119.2pt" }} valign="top">
                        <p>
                            Le prestataire a alors acc&egrave;s aux donn&eacute;es communiqu&eacute;es par
                            l&rsquo;Utilisateur lors de la cr&eacute;ation de son Compte, &agrave; savoir son nom,
                            pr&eacute;nom et adresse e-mail ainsi que toute information qui pourrait &ecirc;tre
                            li&eacute;e &agrave; l&rsquo;utilisation de son Compte.&nbsp;&nbsp;
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
        <p>
            <strong>
                Nous n&rsquo;offrons pas ni ne vendons vos donn&eacute;es &agrave; caract&egrave;re personnel.
            </strong>
        </p>
        <p>
            Labrador&nbsp;s&rsquo;engage &agrave; ne pas communiquer les Donn&eacute;es collect&eacute;es,&nbsp;&agrave;
            un tiers sans recueillir le consentement de l&rsquo;Utilisateur, en dehors des cas o&ugrave; une
            sollicitation &eacute;manerait d&rsquo;une autorit&eacute; ou d&rsquo;un organisme &eacute;tatique, dans le
            cadre de l&rsquo;ex&eacute;cution des obligations l&eacute;gales ou r&eacute;glementaires en
            vigueur.&nbsp;En tout &eacute;tat de cause, Labrador&nbsp;s&rsquo;est assur&eacute;e de l&rsquo;engagement
            de ses prestataires ou sous-traitants &agrave; respecter la R&eacute;glementation.&nbsp;
        </p>
        <h3>
            <strong>O&ugrave; sont h&eacute;berg&eacute;es les donn&eacute;es collect&eacute;es ? </strong>
        </h3>
        <p>
            Labrador informe l&rsquo;Utilisateur que ses Donn&eacute;es sont h&eacute;berg&eacute;es chez Cyllene&nbsp;:
        </p>
        <p>
            <strong>
                Cyllene
                <br />
                92, rue R&eacute;aumur
                <br />
                75002 Paris
                <br />
            </strong>
            <a href="https://www.google.com/url?q=https://www.groupe-cyllene.com/fr/politique_confidentialite&amp;sa=D&amp;source=hangouts&amp;ust=1619522237924000&amp;usg=AFQjCNGD-U3xca8a0L_bc2qDzlZXTxpF1A">
                https://www.groupe-cyllene.com/fr/politique_confidentialite
            </a>
        </p>
        <h3>
            <strong>Transfert de Donn&eacute;es personnelles en dehors de l&rsquo;Union Europ&eacute;enne</strong>
        </h3>
        <p>
            Labrador est un groupe international dont certaines de ses filiales sont situ&eacute;es en dehors de
            l&rsquo;Union Europ&eacute;enne (ci-apr&egrave;s &laquo;&nbsp;UE). Toutefois, les donn&eacute;es
            collect&eacute;es via le Service ne font l&rsquo;objet d&rsquo;aucun transfert en dehors de
            l&rsquo;UE.&nbsp;
        </p>
        <p>
            Dans le cas o&ugrave; les Donn&eacute;es Personnelles feraient l&rsquo;objet d&rsquo;un transfert dans un
            pays situ&eacute; hors de l&rsquo;UE, nous nous engageons &agrave; informer pr&eacute;alablement
            l&rsquo;Utilisateur, &agrave; moins d&rsquo;en &ecirc;tre exon&eacute;r&eacute; par un motif l&eacute;gitime
            ou conform&eacute;ment &agrave; une obligation l&eacute;gale ou r&eacute;glementaire.&nbsp;
        </p>
        <p>
            En cas de transfert de Donn&eacute;es Personnelles en dehors de l&rsquo;UE, nous nous engageons &agrave;
            respecter les garanties appropri&eacute;es pr&eacute;vues par le RGPD, par la mise en place de clauses
            contractuelles type et par la mise en &oelig;uvre de mesures techniques et organisationnelles
            n&eacute;cessaires pour assurer le m&ecirc;me niveau de protection que celui exig&eacute; par la
            r&eacute;glementation europ&eacute;enne en mati&egrave;re de donn&eacute;es personnelles.
        </p>
        <h3>
            <strong>Quelles sont les obligations de Labrador ? </strong>
        </h3>
        <p>
            En qualit&eacute; de responsable des traitements d&eacute;finis aux pr&eacute;sentes, Labrador
            s&rsquo;engage &agrave; :
        </p>
        <ul>
            <li>
                Mettre en place toutes les mesures techniques et organisationnelles appropri&eacute;es pour garantir la
                confidentialit&eacute;, l&rsquo;int&eacute;grit&eacute; et la s&eacute;curit&eacute; des Donn&eacute;es,
                y compris la protection contre leur perte, destruction ou d&eacute;g&acirc;ts d&rsquo;origine
                accidentelle ;
            </li>
            <li>
                Sensibiliser et former son personnel en interne au traitement des Donn&eacute;es, &agrave; la
                R&eacute;glementation en vigueur et &agrave; ses cons&eacute;quences ;
            </li>
            <li>
                Restreindre l&rsquo;acc&egrave;s aux Donn&eacute;es des Utilisateurs aux seules personnes d&ucirc;ment
                habilit&eacute;es &agrave; cet effet ;
            </li>
            <li>Garantir tous les droits des Utilisateurs dans les conditions d&eacute;finies au Point 9. ;</li>
            <li>
                Notifier &agrave; l&rsquo;autorit&eacute; de contr&ocirc;le comp&eacute;tente et aux Utilisateurs
                l&rsquo;&eacute;ventuelle violation de Donn&eacute;es pr&eacute;sentant un risque &eacute;lev&eacute;
                pour les droits et libert&eacute;s des Utilisateurs dans un d&eacute;lai de soixante-douze (72) heures
                suivant la d&eacute;couverte de l&rsquo;intrusion frauduleuse si la R&eacute;glementation l&rsquo;exige
                ;
            </li>
            <li>
                Respecter les dur&eacute;es de conservation des Donn&eacute;es d&eacute;finies aux pr&eacute;sentes.
            </li>
        </ul>
        <h3>
            <strong>Quels sont les droits des utilisateurs sur les donn&eacute;es collect&eacute;es ? </strong>
        </h3>
        <p>
            Conform&eacute;ment &agrave; la r&eacute;glementation applicable en mati&egrave;re de donn&eacute;es
            personnelles et notamment aux articles 15, 16, 17, 18, 20 et 21 du RGPD, l&rsquo;Utilisateur dispose et peut
            exercer les droits suivants aupr&egrave;s de Labrador :&nbsp;
        </p>
        <table border={0} cellSpacing="0" cellPadding="0">
            <thead>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "147.5pt" }} valign="top">
                        <p>
                            <strong>VOS DROITS</strong>
                        </p>
                    </td>
                    <td style={{ width: "303.25pt" }} valign="top">
                        <p>
                            <strong>CE QUE CELA SIGNIFIE</strong>
                        </p>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "147.5pt" }} valign="top">
                        <p>
                            Le droit&nbsp;<strong>d&rsquo;&ecirc;tre inform&eacute;</strong>
                        </p>
                    </td>
                    <td style={{ width: "303.25pt" }} valign="top">
                        <p>
                            Vous avez le droit d&rsquo;obtenir des informations claires, transparentes,
                            compr&eacute;hensibles et ais&eacute;ment accessibles sur la mani&egrave;re dont nous
                            utilisons vos Donn&eacute;es Personnelles et sur vos droits. C&rsquo;est la raison pour
                            laquelle nous vous fournissons les informations dans cette Politique.
                        </p>
                    </td>
                </tr>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "147.5pt" }} valign="top">
                        <p>
                            Le droit&nbsp;<strong>d&rsquo;acc&egrave;s</strong>
                        </p>
                    </td>
                    <td style={{ width: "303.25pt" }} valign="top">
                        <p>
                            Vous avez le droit d&rsquo;acc&eacute;der aux Donn&eacute;es Personnelles dont nous
                            disposons sur vous (sous r&eacute;serve de certaines restrictions).&nbsp;
                        </p>
                    </td>
                </tr>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "147.5pt" }} valign="top">
                        <p>
                            Le droit de&nbsp;<strong>rectification</strong>
                        </p>
                    </td>
                    <td style={{ width: "303.25pt" }} valign="top">
                        <p>
                            Vous avez le droit d&rsquo;exiger que vos Donn&eacute;es Personnelles soient
                            rectifi&eacute;es si elles sont inexactes ou p&eacute;rim&eacute;es et/ou soient
                            compl&eacute;t&eacute;es si elles sont incompl&egrave;tes ou mises &agrave; jour.
                        </p>
                    </td>
                </tr>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "147.5pt" }} valign="top">
                        <p>
                            Le droit &agrave;&nbsp;<strong>l&rsquo;effacement/droit </strong>
                        </p>
                        <p>
                            <strong>&agrave; l&rsquo;oubli</strong>
                        </p>
                    </td>
                    <td style={{ width: "303.25pt" }} valign="top">
                        <p>
                            Dans certains cas, vous avez le droit d&rsquo;obtenir l&rsquo;effacement ou la suppression
                            de vos Donn&eacute;es Personnelles. Ceci n&rsquo;est pas un droit absolu, dans la mesure
                            o&ugrave; nous pouvons &ecirc;tre contraints de conserver vos Donn&eacute;es Personnelles
                            pour des motifs l&eacute;gaux.
                        </p>
                    </td>
                </tr>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "147.5pt" }} valign="top">
                        <p>
                            Le droit de{" "}
                            <strong>
                                retirer son consentement &agrave; tout moment pour un traitement de donn&eacute;es
                                fond&eacute; sur le consentement
                            </strong>
                        </p>
                    </td>
                    <td style={{ width: "303.25pt" }} valign="top">
                        <p>
                            Vous pouvez retirer votre consentement au traitement de vos donn&eacute;es si ce traitement
                            est fond&eacute; sur le consentement. Le retrait de ce consentement ne porte pas atteinte
                            &agrave; la lic&eacute;it&eacute; du traitement fond&eacute; sur le consentement
                            effectu&eacute; avant le retrait de celui-ci. Nous vous invitons &agrave; consulter le
                            tableau ins&eacute;r&eacute; dans la partie &laquo; Quelles Donn&eacute;es Personnelles
                            collectons-nous aupr&egrave;s de vous et comment les utilisons-nous ? &raquo;, et en
                            particulier la colonne &laquo; Sur quelle base juridique repose le traitement de vos
                            Donn&eacute;es Personnelles auquel nous proc&eacute;dons ? &raquo;, pour savoir si notre
                            traitement est fond&eacute; sur le consentement.
                        </p>
                    </td>
                </tr>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "147.5pt" }} valign="top">
                        <p>
                            Le droit&nbsp;
                            <strong>
                                d'introduire une r&eacute;clamation aupr&egrave;s d'une autorit&eacute; de
                                contr&ocirc;le
                            </strong>
                        </p>
                    </td>
                    <td style={{ width: "303.25pt" }} valign="top">
                        <p>
                            Vous avez le droit de saisir et d&rsquo;introduire une r&eacute;clamation aupr&egrave;s de
                            l&rsquo;autorit&eacute; de protection des donn&eacute;es de votre pays pour contester des
                            pratiques en mati&egrave;re de protection des Donn&eacute;es Personnelles et de respect de
                            la vie priv&eacute;e.&nbsp;
                        </p>
                        <p>
                            Nous vous invitons &agrave; nous contacter aux coordonn&eacute;es ci-dessous avant
                            d&rsquo;introduire toute r&eacute;clamation aupr&egrave;s de l&rsquo;autorit&eacute; de
                            protection des donn&eacute;es comp&eacute;tente.
                        </p>
                    </td>
                </tr>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "147.5pt" }} valign="top">
                        <p>
                            Le droit &agrave;&nbsp;<strong>la portabilit&eacute; des donn&eacute;es</strong>
                        </p>
                    </td>
                    <td style={{ width: "303.25pt" }} valign="top">
                        <p>
                            Vous avez le droit de d&eacute;placer, copier ou transmettre les donn&eacute;es vous
                            concernant de notre base de donn&eacute;es &agrave; une autre. Cela s&rsquo;applique
                            uniquement aux donn&eacute;es que vous avez fournies, lorsque le traitement est fond&eacute;
                            sur votre consentement ou sur un contrat et qu&rsquo;il est effectu&eacute; &agrave; l'aide
                            de proc&eacute;d&eacute;s automatis&eacute;s. Nous vous invitons &agrave; consulter le
                            tableau ins&eacute;r&eacute; dans la partie &laquo; Quelles Donn&eacute;es Personnelles
                            collectons-nous aupr&egrave;s de vous et comment les utilisons-nous ? &raquo;, et en
                            particulier la colonne &laquo; Sur quelle base juridique repose le traitement de vos
                            Donn&eacute;es Personnelles auquel nous proc&eacute;dons ? &raquo;, pour savoir si notre
                            traitement est fond&eacute; sur un contrat ou sur le consentement.&nbsp;
                        </p>
                    </td>
                </tr>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "147.5pt" }} valign="top">
                        <p>
                            Le droit &agrave;&nbsp;la <strong>limitation du traitement </strong>
                        </p>
                    </td>
                    <td style={{ width: "303.25pt" }} valign="top">
                        <p>
                            Vous avez le droit de demander la limitation du traitement que nous effectuons sur les
                            donn&eacute;es vous concernant.
                        </p>
                        <p>
                            Ce droit signifie que le traitement des donn&eacute;es vous concernant auquel nous
                            proc&eacute;dons est limit&eacute;, de sorte que nous pouvons conserver ces donn&eacute;es,
                            mais pas les utiliser, ni les traiter.
                        </p>
                        <p>
                            Ce droit s&rsquo;applique dans des circonstances particuli&egrave;res pr&eacute;vues par le
                            RGPD &agrave; savoir :
                        </p>
                        <ul>
                            <li>
                                L&rsquo;exactitude des Donn&eacute;es Personnelles est contest&eacute;e par la personne
                                concern&eacute;e (c&rsquo;est-&agrave;-dire vous), pendant une dur&eacute;e permettant
                                au responsable du traitement de v&eacute;rifier l'exactitude des Donn&eacute;es
                                Personnelles ;
                            </li>
                            <li>
                                Le traitement est illicite et la personne concern&eacute;e (c&rsquo;est-&agrave;-dire
                                vous) s'oppose &agrave; leur effacement et exige &agrave; la place la limitation de leur
                                utilisation ;
                            </li>
                            <li>
                                Le responsable du traitement (c&rsquo;est-&agrave;-dire Labrador) n'a plus besoin des
                                Donn&eacute;es Personnelles aux fins du traitement mais celles-ci sont encore
                                n&eacute;cessaires &agrave; la personne concern&eacute;e pour la constatation,
                                l'exercice ou la d&eacute;fense de droits en justice ;
                            </li>
                            <li>
                                La personne concern&eacute;e (c&rsquo;est-&agrave;-dire vous) s'est oppos&eacute;e au
                                traitement fond&eacute; sur les int&eacute;r&ecirc;ts l&eacute;gitimes poursuivis par le
                                responsable du traitement pendant la v&eacute;rification portant sur le point de savoir
                                si les motifs l&eacute;gitimes poursuivis par le responsable du traitement
                                pr&eacute;valent sur ceux de la personne concern&eacute;e.
                            </li>
                        </ul>
                    </td>
                </tr>
            </tbody>
        </table>
        <p>
            L&rsquo;Utilisateur peut exercer ces droits &agrave; tout moment en contactant le service
            d&eacute;di&eacute; aux adresses suivantes :
        </p>
        <table border={0} cellSpacing="0" cellPadding="0">
            <thead>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "225.4pt" }} valign="top">
                        <p>
                            <strong>
                                <em>Par courrier :</em>
                            </strong>
                        </p>
                    </td>
                    <td style={{ width: "225.4pt" }} valign="top">
                        <p>
                            <strong>
                                <em>Par email :</em>
                            </strong>
                        </p>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "225.4pt" }} valign="top">
                        <p>
                            <strong>[nom du responsable de la gestion des droits]</strong>
                        </p>
                        <p>Labrador</p>
                        <p>8 Rue Lantiez,</p>
                        <p>75017 Paris</p>
                    </td>
                    <td style={{ width: "225.4pt" }} valign="top">
                        <p>
                            <a href="mailto:contact@labrador-company.com">contact@labrador-company.com</a>
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
        <p className="!mb-0">L&rsquo;Utilisateur, pour exercer ses droits, fournit &agrave; Labrador :</p>
        <ul>
            <li>L&rsquo;origine de la collecte de ses Donn&eacute;es (formulaire de contact par exemple) ;</li>
            <li>Son identit&eacute; et coordonn&eacute;es.</li>
        </ul>
        <p>
            En cas de &laquo; doute raisonnable &raquo; sur l&rsquo;identit&eacute; du demandeur (par exemple en cas de
            demande formul&eacute;e via une adresse &eacute;lectronique inconnue), et afin de pr&eacute;venir des
            usurpations d&rsquo;identit&eacute; ou des risques d&rsquo;homonymie, Labrador pourra valablement solliciter
            la communication d&rsquo;une preuve de l&rsquo;identit&eacute;.
        </p>
        <p>
            Labrador dispose d&rsquo;un d&eacute;lai de un (1) mois &agrave; compter de la r&eacute;ception de la
            demande pour y r&eacute;pondre, sous r&eacute;serve du bien-fond&eacute; de la demande et de son
            caract&egrave;re non manifestement excessif.
        </p>
        <p>
            L&rsquo;Utilisateur peut &eacute;galement &agrave; tout moment refuser que ses Donn&eacute;es soient
            utilis&eacute;es par Labrador.
        </p>
        <p>
            Pour ce faire, l&rsquo;Utilisateur est invit&eacute; &agrave; cliquer sur les liens de d&eacute;sinscription
            fournis dans les emails envoy&eacute;s par Labrador.
        </p>
        <p>
            L&rsquo;Utilisateur est inform&eacute; qu&rsquo;il peut, s&rsquo;il estime que ses droits n&rsquo;ont pas
            &eacute;t&eacute; respect&eacute;s, effectuer une r&eacute;clamation aupr&egrave;s de la Commission
            Nationale de l&rsquo;Informatique et des Libert&eacute;s (ci-apr&egrave;s, la &laquo;{" "}
            <strong>
                <em>CNIL</em>
            </strong>{" "}
            &raquo;). Labrador invite l&rsquo;Utilisateur &agrave; se rendre sur le lien suivant pour davantage
            d&rsquo;informations :{" "}
            <a href="https://www.cnil.fr/fr/les-droits-pour-maitriser-vos-donnees-personnelles">
                https://www.cnil.fr/fr/les-droits-pour-maitriser-vos-donnees-personnelles
            </a>
            .
        </p>
        <h3>
            <strong>Gestion des cookies</strong>
        </h3>
        <p className="!mb-0">
            Un &laquo; cookie &raquo; est un fichier texte plac&eacute; par Labrador sur le terminal de
            l&rsquo;Utilisateur lors de sa navigation via le Service. Le cookie enregistre des informations relatives
            &agrave; la navigation de l&rsquo;Utilisateur via le Service et stocke des informations que
            l&rsquo;Utilisateur saisit durant sa visite. L'usage de cookies permet &eacute;galement d&rsquo;offrir aux
            Utilisateurs la meilleure exp&eacute;rience d'utilisation. Les cookies d&eacute;pos&eacute;s, permettent
            ainsi de :
        </p>
        <ul>
            <li>Optimiser l&rsquo;ergonomie du Service et l&rsquo;exp&eacute;rience de navigation ;</li>
            <li>
                M&eacute;moriser les pr&eacute;f&eacute;rences afin que vous ne deviez pas syst&eacute;matiquement
                introduire ou t&eacute;l&eacute;charger &agrave; nouveau les m&ecirc;mes informations lorsque vous
                utilisez notre Service ;
            </li>
            <li>Am&eacute;liorer la s&eacute;curit&eacute; et la vitesse de notre Service.</li>
        </ul>
        <p>
            Pour toute information sur des Cookies sp&eacute;cifiques plac&eacute;s par le biais d&rsquo;un site
            Internet donn&eacute; (partenaires/tiers), veuillez consulter le tableau relatif aux cookies figurant sur le
            site Internet du partenaire/tiers. &nbsp;
        </p>
        <p>
            Labrador informe l&rsquo;Utilisateur placer les cookies suivants pour les fonctions suivantes{" "}
            <strong>:</strong>
        </p>

        <table border={0} cellSpacing="0" cellPadding="0">
            <thead>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "77.25pt" }} valign="top">
                        <p>
                            <strong>Nom du Cookie d&eacute;pos&eacute;</strong>
                        </p>
                    </td>
                    <td style={{ width: "61.8pt" }} valign="top">
                        <p>
                            <strong>Type de Cookie d&eacute;pos&eacute;</strong>
                        </p>
                    </td>
                    <td style={{ width: "132.7pt" }} valign="top">
                        <p>
                            <strong>Provenance</strong>
                        </p>
                    </td>
                    <td style={{ width: "119.35pt" }} valign="top">
                        <p>
                            <strong>Fonctions</strong>
                        </p>
                    </td>
                    <td style={{ width: "59.7pt" }} valign="top">
                        <p>
                            <strong>Dur&eacute;e de conservation sur le terminal </strong>
                        </p>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "77.25pt" }} valign="top">
                        <p>Cookies_consent_status</p>
                    </td>
                    <td style={{ width: "61.8pt" }} valign="top">
                        <p>Fonctionnel</p>
                    </td>
                    <td style={{ width: "132.7pt" }} valign="top">
                        <p>&nbsp;</p>
                    </td>
                    <td style={{ width: "119.35pt" }} valign="top">
                        <p>Optimiser l&rsquo;exp&eacute;rience du Service et garantir son bon fonctionnement :</p>
                        <ul>
                            <li>Analyser les connexions de l&rsquo;internaute au Service ;</li>
                            <li>&Eacute;tablir la communication entre le serveur web et le navigateur ;</li>
                            <li>Permettre le bon affichage du Service en fonction des informations du navigateur.</li>
                        </ul>
                    </td>
                    <td style={{ width: "59.7pt" }} valign="top">
                        <p>1 an</p>
                    </td>
                </tr>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "77.25pt" }} valign="top">
                        <p>Google Analytics</p>
                    </td>
                    <td style={{ width: "61.8pt" }} valign="top">
                        <p>Analytique</p>
                    </td>
                    <td style={{ width: "132.7pt" }} valign="top">
                        <p>Google :</p>
                        <p>
                            <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>
                        </p>
                    </td>
                    <td style={{ width: "119.35pt" }}>
                        <p>
                            Analyse anonyme des pages consacr&eacute;s au Service visit&eacute;s par l&rsquo;Utilisateur
                            :
                        </p>
                        <ul>
                            <li>Compter et suivre les pages visit&eacute;es et le nombre visiteurs ;</li>
                            <li>Filtrer les requ&ecirc;tes des robots ;</li>
                            <li>Stocker des statistiques de fr&eacute;quentation anonymes.</li>
                        </ul>
                    </td>
                    <td style={{ width: "59.7pt" }} valign="top">
                        <p>1 an</p>
                    </td>
                </tr>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "77.25pt" }} valign="top">
                        <p>cookieControlPrefs</p>
                    </td>
                    <td style={{ width: "61.8pt" }} valign="top">
                        <p>Fonctionnel</p>
                    </td>
                    <td style={{ width: "132.7pt" }} valign="top">
                        <p>&nbsp;</p>
                    </td>
                    <td style={{ width: "119.35pt" }}>
                        <p>
                            Enregistrer les pr&eacute;f&eacute;rences de consentement aux cookies de
                            l&rsquo;Utilisateur.
                        </p>
                    </td>
                    <td style={{ width: "59.7pt" }} valign="top">
                        <p>6 mois</p>
                    </td>
                </tr>
            </tbody>
        </table>
        <p>
            L&rsquo;Utilisateur est invit&eacute; &agrave; donner son consentement de mani&egrave;re globale au
            placement de ces cookies en cliquant sur &laquo;&nbsp;Tout Accepter &raquo; dans le bandeau
            d&rsquo;informations apparaissant en bas de la page consacr&eacute;e au Service au cours de sa navigation.
            Il pourra &eacute;galement y consentir au cas par cas gr&acirc;ce au menu d&eacute;roulant chacun des types
            de cookies d&eacute;pos&eacute;s et finalit&eacute;s associ&eacute;es.
        </p>
        <p>
            L&rsquo;Utilisateur peut &eacute;galement d&eacute;sactiver globalement les Cookies en cliquant sur le
            bouton &laquo;&nbsp;Tout Refuser &raquo; dans le bandeau d&rsquo;informations pr&eacute;cit&eacute;.
            Toutefois concernant les cookies servant &agrave; am&eacute;liorer l&rsquo;ergonomie et le fonctionnement du
            Service, les d&eacute;sactiver pourraient vous emp&ecirc;cher d&rsquo;utiliser certaines parties du Service.
            &nbsp;&nbsp;
        </p>
        <p className="!mb-0">
            Nonobstant certains cookies de mesure d&rsquo;audience qui ne peuvent &ecirc;tre param&eacute;tr&eacute;s
            car &eacute;tant essentiels au bon fonctionnement du Service, l&rsquo;Utilisateur peut s&rsquo;opposer au
            placement de cookies directement depuis son navigateur de la mani&egrave;re suivante :&nbsp;
        </p>
        <ul>
            <li>
                Pour Mozilla Firefox: choisir le menu "outil " puis "Options" / Cliquer sur l'ic&ocirc;ne "Vie
                priv&eacute;e" / Rep&eacute;rer le menu "cookie" et s&eacute;lectionner les options
                souhait&eacute;es&nbsp;;&nbsp;
            </li>
            <li>
                Pour Microsoft Internet Explorer : choisir le menu "Outils" puis "Options Internet" / cliquer sur
                l'onglet "Confidentialit&eacute;" / s&eacute;lectionner le niveau souhait&eacute; &agrave; l'aide du
                curseur&nbsp;;&nbsp;
            </li>
            <li>
                Pour Op&eacute;ra 6.0 et ses versions ult&eacute;rieures : choisir le menu
                "Fichier"&gt;"Pr&eacute;f&eacute;rences" / "Vie Priv&eacute;e"&nbsp;;&nbsp;
            </li>
            <li>
                Pour Safari&nbsp;: choisir le menu "Edition" &gt;&nbsp;"Pr&eacute;f&eacute;rences" /
                "S&eacute;curit&eacute;. " Cliquer sur "Affichez les cookies" et s&eacute;lectionner les cookies
                concern&eacute;s puis cliquer sur "Effacer" / "Termin&eacute;"&nbsp;;&nbsp;
            </li>
            <li>
                Pour Google Chrome&nbsp;: Cliquer sur
                &laquo;&nbsp;Outils&nbsp;&raquo;&gt;&nbsp;&laquo;&nbsp;Options&nbsp;&raquo; &gt; &laquo;&nbsp;Options
                avanc&eacute;es&nbsp;&raquo;, "Confidentialit&eacute;", "Afficher les cookies" puis supprimer les
                fichiers concern&eacute;s. Enfin, cliquer sur "Fermer".
            </li>
        </ul>
        <p>
            L&rsquo;Utilisateur est inform&eacute; que la dur&eacute;e de conservation maximale de ces informations sur
            son terminal est de un (1) an.
        </p>
        <p>
            L&rsquo;Utilisateur peut &eacute;galement s&rsquo;opposer au placement de cookies en se rendant sur la
            plateforme de gestion des cookies publicitaires propos&eacute;e par les professionnels de la
            publicit&eacute; accessible &agrave; l&rsquo;adresse
        </p>
        <p>
            <a href="http://www.youronlinechoices.com/fr/controler-ses-cookies/">
                http://www.youronlinechoices.com/fr/controler-ses-cookies/
            </a>{" "}
            et en suivant les instructions qui y sont donn&eacute;es.&nbsp;
        </p>
        <p>
            &nbsp; En tout &eacute;tat de cause l&rsquo;Utilisateur qui aura exprim&eacute; ses choix en termes de
            cookies ne se verra plus opposer le bandeau d&rsquo;informations lors de ses prochaines visites et ce, pour
            une dur&eacute;e de six (6) mois. L&rsquo;Utilisateur pourra, toutefois, avoir acc&egrave;s &agrave; ses
            choix et les modifier &agrave; tout moment, gr&acirc;ce &agrave; l&rsquo;onglet{" "}
            <em>Param&eacute;trer mes Cookies</em> qui permettra de rouvrir le bandeau d&rsquo;informations
            m&eacute;moris&eacute;.
        </p>
        <h3>
            <strong>Propri&eacute;t&eacute;</strong>
        </h3>
        <p>
            Le Service et les diff&eacute;rents &eacute;l&eacute;ments le composant sont prot&eacute;g&eacute;s par le
            droit de la propri&eacute;t&eacute; intellectuelle et industrielle. Est interdite toute
            repr&eacute;sentation, toute reproduction et, de mani&egrave;re plus g&eacute;n&eacute;rale, toute
            exploitation &agrave; titre on&eacute;reux ou gratuit, pour quelque finalit&eacute; que ce soit, par quelque
            proc&eacute;d&eacute; que ce soit, de tout ou partie du Service (notamment de sa structure
            g&eacute;n&eacute;rale, des photographies, des informations, des ic&ocirc;nes, sigles, logos, graphismes,
            marques, illustrations, textes y figurant, sans que cette liste soit limitative), &agrave; des fins
            commerciales ou non, sans l&rsquo;autorisation pr&eacute;alable et &eacute;crite de Labrador. Les
            informations contenues via le Service sont strictement r&eacute;serv&eacute;es &agrave; un usage personnel,
            priv&eacute; et gratuit. Tout usage contraire &agrave; la pr&eacute;sente clause est constitutif
            d&rsquo;acte de contrefa&ccedil;on p&eacute;nalement et civilement r&eacute;pr&eacute;hensible.
        </p>
        <h3>
            <strong>Liens Hypertexte</strong>
        </h3>
        <p>
            Par le pr&eacute;sent Service l&rsquo;Utilisateur pourra acc&eacute;der &agrave; des sites, con&ccedil;us et
            g&eacute;r&eacute;s sous la responsabilit&eacute; de tiers.
        </p>
        <p>
            L&rsquo;Utilisateur est inform&eacute; que Labrador n&rsquo;exerce aucun contr&ocirc;le sur les contenus
            desdits sites. En outre, Labrador d&eacute;cline toute responsabilit&eacute; s&rsquo;agissant notamment de
            leur contenu et de l&rsquo;utilisation qui serait faite par tout tiers des informations y figurant.
        </p>
        <h3>
            <strong>Int&eacute;grit&eacute; - D&eacute;faillances Techniques</strong>
        </h3>
        <p>
            Labrador se r&eacute;serve le droit d&rsquo;interrompre temporairement l&rsquo;acc&egrave;s au Service URD
            Digital pour effectuer les interventions techniques, d&rsquo;am&eacute;lioration et de maintenance du
            Service pour assurer son bon fonctionnement ou r&eacute;parer une panne.
        </p>
        <p>
            L&rsquo;Utilisateur est inform&eacute; que la s&eacute;curit&eacute; et l&rsquo;int&eacute;grit&eacute; des
            communications sur Internet ne peuvent &ecirc;tre garanties. Labrador s&rsquo;engage &agrave; fournir ses
            meilleurs efforts pour pr&eacute;venir l&rsquo;Utilisateur de toute difficult&eacute; d&rsquo;acc&egrave;s
            au Service ou toute interruption de diffusion du Document d&rsquo;enregistrement universel en ligne, sans
            obligation de r&eacute;sultat.
        </p>
        <h3>
            <strong>Loi et Territoire De Juridiction</strong>
        </h3>
        <p>
            La loi applicable est la loi fran&ccedil;aise, les tribunaux comp&eacute;tents sont les tribunaux
            fran&ccedil;ais dans le respect des r&egrave;gles du Code de proc&eacute;dure civile.
        </p>
        <h3>
            <strong>Changements</strong>
        </h3>
        <p>
            La pr&eacute;sente politique peut &ecirc;tre modifi&eacute;e ou compl&eacute;t&eacute;e &agrave; tout moment
            par Labrador aux fins notamment de mise &agrave; jour en fonction des &eacute;volutions de la
            l&eacute;gislation et r&eacute;glementation en vigueur, sous r&eacute;serve d&rsquo;en informer
            l&rsquo;Utilisateur. En cas de doute, la derni&egrave;re version est celle figurant sur la page
            consacr&eacute;e au Service.
        </p>
        <p>
            Cependant, les Donn&eacute;es Personnelles des Utilisateurs seront toujours trait&eacute;es
            conform&eacute;ment à la politique en vigueur au moment de leur collecte, sauf si une prescription
            l&eacute;gale imp&eacute;rative venait à en disposer autrement et &eacute;tait d'application
            r&eacute;troactive.
        </p>
        <h3>
            <strong>Contact</strong>
        </h3>
        <p className="!mb-0">
            Pour toute question relative &agrave; la collecte ou au traitement des Donn&eacute;es collect&eacute;es,
            l&rsquo;Utilisateur est invit&eacute; &agrave; contacter Labrador :
        </p>
        <ul>
            <li>Par t&eacute;l&eacute;phone : 01 53 06 30 80 ;</li>
            <li>Par courrier : Labrador, 8 rue Lantiez, 75017 Paris ;</li>
            <li>
                Par email : <a href="mailto:contact@labrador-company.com">contact@labrador-company.com</a>
            </li>
        </ul>
        <p>&nbsp;</p>
        <p>
            Pour toute information compl&eacute;mentaire, vous pouvez consulter la page de politique de protections de
            donn&eacute;es de{" "}
            <a href="https://guide_de_la_communication_financiere_le_cliff.fr.digital-report.net/politique-de-protection-des-donnees">
                LE CLIFF
            </a>
            .
        </p>
    </div>
);

export default PrivacyPolicyFR;
