import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useState,
  useEffect
} from "react";
import i18next, { changeLanguage } from "i18next";

export type SupportedLanguages = "en" | "fr";

interface ILanguage {
  language: SupportedLanguages;
  setLanguage: Dispatch<SetStateAction<SupportedLanguages>>;
}

export const LanguageContext = createContext<ILanguage>({
  language: "en",
  setLanguage: () => null,
});

const LanguageProvider = ({ children }: { children: ReactNode }) => {
  const { language: iLang } = i18next;
  const browserLang = iLang.split("-")[0];

  const region = import.meta.env.VITE_REGION || "us";
  const defaultLang:SupportedLanguages = region === "us" || browserLang === "en" ? "en" : "fr";
  
  const [language, setLanguage] = useState<SupportedLanguages>(defaultLang);

  useEffect(() => {
      setLanguage(defaultLang);
      changeLanguage(defaultLang);
  }, []);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
