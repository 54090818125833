import TermsAndConditions from "./TermsAndConditionsEN";
import PrivacyPolicy from "./PrivacyPolicyEN";
import LegalNotice from "./LegalNoticeEN";
import TermsAndConditionsFR from "./TermsAndConditionsFR";
import PrivacyPolicyFR from "./PrivacyPolicyFR";
import LegalNoticeFR from "./LegalNoticeFR";

const documentListFR = {
    en: [
        { title: `legalDocs.terms&Conditions`, content: TermsAndConditions },
        { title: `legalDocs.privacyPolicy`, content: PrivacyPolicy },
        { title: `legalDocs.legalNotice`, content: LegalNotice },
    ],
    fr: [
        { title: `legalDocs.terms&Conditions`, content: TermsAndConditionsFR },
        { title: `legalDocs.privacyPolicy`, content: PrivacyPolicyFR },
        { title: `legalDocs.legalNotice`, content: LegalNoticeFR },
    ],
};

export default documentListFR;
