const PrivacyPolicy = (
    <div>
        <h3 style={{ marginTop: 0 }}>
            <strong>PRIVACY POLICY – PERSONAL DATA</strong>
        </h3>
        <p>
            As part of its online service for the Universal Registration Document &ndash; Digital URD &ndash; the
            Labrador Group (hereinafter "Labrador") is required to process your data. This applies when you access the
            Service, consult the Universal Registration Document, create an Account or when you fill out a form to
            obtain the document in hard copy or to contact us: you send us certain information, some of which might
            enable you to be identified (hereinafter "Personal Data").
        </p>
        <p>
            Labrador respects your privacy and is committed to protecting your Personal Data. The purpose of this
            Privacy Policy is to inform you of the way in which your Personal Data is processed, its possible transfer
            to third parties as well as your rights and the options available to you to control your Personal Data and
            protect your privacy.
        </p>
        <p>We invite you to read this Privacy Policy carefully.</p>
        <h3>
            <strong>1.</strong> <strong>What is Personal Data?</strong>
        </h3>
        <p>
            Personal Data means any information that may identify you directly (e.g. your first and family name or email
            address) or indirectly (e.g. through a unique identifier).
        </p>
        <p>
            This means that Personal Data include information such as email addresses, mobile phone numbers, usernames,
            personal preferences, user-generated content, financial data and information relating to your health.
            Personal Data may also include unique digital identifiers such as the IP address of your computer or the MAC
            address of your mobile device, as well as cookies.
        </p>
        <h3>
            <strong>2.</strong> <strong>Who is responsible for processing your Personal Data?</strong>
        </h3>
        <p>
            Labrador, a simplified joint-stock company located at 8, rue Lantiez, 75017 Paris is the data controller for
            personal data collected via the Digital URD service, hereinafter the "Service" within the meaning of the
            applicable personal data regulations and in particular the EU Regulation 2016/679 on the protection of
            natural persons with regard to the processing of Personal Data and the free movement of such data
            (hereinafter "GDPR").
        </p>
        <h3>
            <strong>3.</strong> <strong>How is your Personal Data collected and for what purposes?&nbsp; </strong>
        </h3>
        <p>
            For Labrador, to deliver clear, consistent and high-performance corporate information is at the heart of
            everything we do. We provide you with a Service accessible to all, some functionalities of which are
            conditional on the creation of an Account, allowing us to receive information from you and discuss with you
            in order to offer you appropriate content, but also to provide you with the necessary assistance when you
            consult certain documents. For all these reasons, there are many ways in which you can communicate your
            Personal Data to us, and in which we can collect them.
        </p>
        <p>
            We may collect Personal Data from you, or receive them from you, via our Service and pages dedicated to
            Digital URD.
        </p>
        <p>
            In some cases, you provide us with Personal Data directly (e.g. when you create an Account for the Service,
            when you wish to obtain the Universal Registration Document in hard copy or when you contact us via the
            relevant form).
        </p>
        <p>
            In other cases, we collect these Personal Data indirectly (e.g., via cookies and other trackers when using
            the Service).
        </p>
        <p>The table below provides more detailed information explaining the following:</p>
        <ul>
            <li>
                <strong>In what situations can your Personal Data be provided or collected?</strong>
                <br />
                <p>
                    This column lists the activities you are engaged in, or situations in which you find yourself, when
                    we use or collect your Personal Data. For example, if you create an Account online, when you submit
                    comments or notes on the Universal Registration Document or when you make a contact request or if
                    you browse one of the pages of the website.
                </p>
            </li>
            <li>
                <strong>
                    What Personal Data might we obtain directly from you or by way of your interactions with us?
                </strong>
                <p>This column indicates what types of data about you we may collect depending on the situation.</p>
            </li>
            <li>
                <strong>How and for what purpose can we use them?</strong>
                <p>This column explains what we can do with your data, and for what purposes we collect it.</p>
            </li>
            <li>
                <strong>What is the legal basis for processing your Personal Data?</strong>
                <p className="!mb-0">
                    This column explains why we may use your Personal Data. Depending on the purpose for which the data
                    are used by Labrador, the legal basis on which the processing of your data is based may be:
                </p>
                <ul>
                    <li>Your consent;</li>
                    <li>Legal obligations when the legislation in force requires the processing of data.</li>
                </ul>
            </li>
        </ul>

        <p>Overview of information about your interactions with us and its impact on your Personal Data:</p>
        <table border={0} cellSpacing="0" cellPadding="0">
            <thead>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "113.25pt" }} valign="top">
                        <p>
                            <strong>In what situations may your Personal Data be provided or collected?</strong>
                        </p>
                    </td>
                    <td style={{ width: "120.75pt" }} valign="top">
                        <p>
                            <strong>
                                What Personal Data might we obtain directly from you or by way of your interactions with
                                us?
                            </strong>
                        </p>
                    </td>
                    <td style={{ width: "126.0pt" }} valign="top">
                        <p>
                            <strong>How and for what purpose can we use them?</strong>
                        </p>
                    </td>
                    <td style={{ width: "89.25pt" }} valign="top">
                        <p>
                            <strong>What is the legal basis for processing your Personal Data?</strong>
                        </p>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "113.25pt" }} valign="top">
                        <p>
                            <strong>Browsing using the Service</strong>
                        </p>
                        <p>
                            Data collected by cookies* during your browsing using the Service (hereinafter "Cookies").
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            For information on specific cookies placed through the Service, please consult the Cookies
                            table at the bottom of the website. *Cookies are small text files that are stored on your
                            device (computer, tablet or mobile) when you use the Digital URD Service.
                        </p>
                    </td>
                    <td style={{ width: "120.75pt" }} valign="top">
                        <ul>
                            <li>Login data;</li>
                            <li>Data relating to the use of the Service;</li>
                            <li>Number of pages viewed by the User;</li>
                            <li>Names of pages viewed by the User;</li>
                            <li>User tracking;</li>
                            <li>Last site visited;</li>
                            <li>Duration of visit;</li>
                            <li>IP address (date, country, region);</li>
                            <li>Data relating to the browser;</li>
                            <li>Data relating to the device.</li>
                        </ul>
                    </td>
                    <td style={{ width: "126.0pt" }} valign="top">
                        <ul>
                            <li>Ensure the proper functioning and security of the Service;</li>
                            <li>Develop statistics to analyze the use of the Service.</li>
                        </ul>
                    </td>
                    <td style={{ width: "89.25pt" }} valign="top">
                        <p>
                            <strong>Consent</strong>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            The validation of the Cookie banner present when accessing the Service allows the consent of
                            the User to be obtained, with the latter being invited to refer to the section "
                            <em>Managing cookies</em>".
                        </p>
                    </td>
                </tr>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "113.25pt" }} valign="top">
                        <p>
                            <strong>Creating and managing an Account</strong>
                        </p>
                        <p>Data collected when creating an Account on the Service by means of a connection</p>
                    </td>
                    <td style={{ width: "120.75pt" }} valign="top">
                        <ul>
                            <li>Family and first name;</li>
                            <li>Email address;</li>
                            <li>Password;</li>
                            <li>Username.</li>
                        </ul>
                    </td>
                    <td style={{ width: "126.0pt" }} valign="top">
                        <ul>
                            <li>
                                Allow Users of the Service to leave notes or comments in the document that are saved and
                                accessible at any time during future visits;
                            </li>
                            <li>
                                Prepare statistics according to the location of comments or notes made by the Users.
                            </li>
                        </ul>
                    </td>
                    <td style={{ width: "89.25pt" }} valign="top">
                        <p>
                            <strong>Consent</strong>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            The validation of the information notice when creating the Account allows Labrador to obtain
                            the consent of the User. It is confirmed by sending an automatic email with a reminder of
                            the data processing methods and the possibility of opposing them.[2] [3] [4]
                        </p>
                    </td>
                </tr>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "113.25pt" }} valign="top">
                        <p>
                            <strong>User rights management</strong> (see Point 9.)
                        </p>
                    </td>
                    <td style={{ width: "120.75pt" }} valign="top">
                        <ul>
                            <li>Family and first name;</li>
                            <li>Postal and/or telephone contact details;</li>
                            <li>
                                Proof of identity,
                                <strong> in case of reasonable doubt about the identity of the person concerned</strong>
                                .
                            </li>
                        </ul>
                    </td>
                    <td style={{ width: "126.0pt" }} valign="top">
                        <ul>
                            <li>Enabling Labrador to respond to Users&rsquo; requests to exercise their rights.</li>
                        </ul>
                    </td>
                    <td style={{ width: "89.25pt" }} valign="top">
                        <p>
                            By exercising your rights as defined in Point 9, Labrador processes your Personal Data in
                            order to comply with its legal or regulatory obligations but also in its legitimate
                            interests, in particular for the prevention of disputes.
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
        <p>
            <em>
                The data marked with an (*) in the forms available via the Service are mandatory for the processing of
                the various requests made by Users. In the event that the User does not wish to communicate these data,
                he or she is informed that the request cannot be processed.
            </em>
        </p>
        <p>
            <em>
                The User undertakes to provide only truthful, accurate, complete and up-to-date data and not to usurp
                those of a third party.{" "}
            </em>
        </p>
        <h3>
            <strong>4.</strong> <strong>How long does Labrador keep the data collected?</strong>
        </h3>
        <p>
            Labrador informs the User that it retains their Personal Data for a period that does not exceed that
            strictly necessary for the purposes for which it was processed, namely:
        </p>
        <ul>
            <li>
                For Personal Data collected during browsing using the Service, the cookies are kept for a period not
                exceeding thirteen (13) months from the date of their placement on the User&rsquo;s device;
            </li>
            <li>
                For Personal Data collected following the creation of an online Account, they are kept for three (3)
                years from the date of deletion of your Account or after a period of inactivity (no login to the
                Account);
            </li>
            <li>
                For Personal Data collected for statistical analysis purposes, the User&rsquo;s IP address is kept for
                one year from the last login;
            </li>
            <li>
                For Personal Data collected following a request to exercise rights (see Point 7), they are kept for one
                (1) year in the event of the exercise of the right of access or rectification from the date of receipt
                of the request, and three (3) years in case of exercise of the right of opposition from receipt of the
                request.
            </li>
        </ul>

        <p>
            When their storage is no longer justified by legal requirements or if you have made use of a right of
            modification or deletion, we will delete your Personal Data in a secure manner.
        </p>
        <h3>
            <strong>5.</strong> <strong>Who are the recipients of the data collected?</strong>
        </h3>
        <p>
            The data collected via the Digital URD Service are intended for Labrador. They are transmitted to the
            company for which the Universal Registration Document is published but also to service providers and
            subcontractors that Labrador may call upon for the management and guarantee of the proper functioning of the
            proposed Service, hosting, maintenance services or technical support for our databases.
        </p>
        <p>
            To provide the User with the most comprehensive information possible, Labrador confirms that it uses the
            following subcontractors:
        </p>
        <table border={0} cellSpacing="0" cellPadding="0">
            <thead>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "82.95pt" }} valign="top">
                        <p>
                            <strong>Subcontractor category</strong>
                        </p>
                    </td>
                    <td style={{ width: "125.6pt" }} valign="top">
                        <p>
                            <strong>Data transmission situation</strong>
                        </p>
                    </td>
                    <td style={{ width: "127.8pt" }} valign="top">
                        <p>
                            <strong>Legal basis for data transmission</strong>
                        </p>
                    </td>
                    <td style={{ width: "114.35pt" }} valign="top">
                        <p>
                            <strong>Type of data collected</strong>
                        </p>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "82.95pt" }} rowSpan={3} valign="top">
                        <p>Company for which the Universal Registration Document is published</p>
                    </td>
                    <td style={{ width: "125.6pt" }} valign="top">
                        <p className="!mb-0">
                            <strong>When the User makes a contact request</strong>[5] [6] <strong>,</strong> the
                            information collected via the relevant form is sent to the said Company, in accordance with
                            its Privacy Policy, which is linked to the form, in order to:
                        </p>
                        <ul>
                            <li>Recontact the User, in particular by email or phone calls;</li>
                            <li>Respond to their requests for information;</li>
                            <li>Connect the User with the appropriate department if necessary.</li>
                        </ul>
                    </td>
                    <td style={{ width: "127.8pt" }} valign="top">
                        <p>
                            <strong>Consent</strong>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            The validation of the information notice in the relevant form when sending the request
                            allows the Company for which the Universal Registration Document is published to obtain the
                            consent of the User. It is confirmed by sending an automatic email with a reminder of the
                            processing methods and the possibility of opposing them.[7]
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            For further information, please consult the Privacy Policy on the link at the bottom of the
                            page.
                        </p>
                    </td>
                    <td style={{ width: "114.35pt" }} valign="top">
                        <p>
                            The company collects the family name, first name, email address and telephone number of the
                            User as well as the content of the message sent to process their request<strong>.</strong>
                        </p>
                    </td>
                </tr>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "125.6pt" }} valign="top">
                        <p>
                            <strong>When the User makes a request for a hard copy document</strong>[8]{" "}
                            <strong>,</strong> the information collected via the relevant form is sent to said Company,
                            in accordance with its Privacy Policy, the link of which is attached to the form, in order
                            to:
                        </p>
                        <ul>
                            <li>Mail the requested documentation.</li>
                        </ul>
                    </td>
                    <td style={{ width: "127.8pt" }} valign="top">
                        <p>
                            <strong>Consent</strong>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            The validation of the information notice in the relevant form when sending the request
                            allows the Company for which the Universal Registration Document is published to obtain the
                            consent of the User. It is confirmed by sending an automatic email with a reminder of the
                            data processing methods and the possibility of opposing them.
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            For further information, please consult the Privacy Policy on the link at the bottom of the
                            page.
                        </p>
                    </td>
                    <td style={{ width: "114.35pt" }} valign="top">
                        <p>
                            The company collects the family name, first name, email address and postal address of the
                            User in order to process their request.
                        </p>
                    </td>
                </tr>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "125.6pt" }} valign="top">
                        <p>
                            <strong>
                                When the User has created an Account and annotated or commented on the Universal
                                Registration Document,
                            </strong>{" "}
                            information relating to the location of the User&rsquo;s comments or annotations is
                            transmitted in the form of anonymous statistics to said Company, for marketing purposes.
                        </p>
                    </td>
                    <td style={{ width: "127.8pt" }} valign="top">
                        <p>
                            <strong>Contract between Labrador and said Company</strong>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            The information transmitted to the Company for which the Universal Registration Document is
                            published are anonymized data relating to location statistics that do not allow
                            identification of the User. This transmission is in response to a contractual obligation
                            incumbent upon Labrador.
                        </p>
                    </td>
                    <td style={{ width: "114.35pt" }} valign="top">
                        <p>
                            The company only collects statistics on the location of comments or annotations made in the
                            Universal Registration Document by the User.
                        </p>
                    </td>
                </tr>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "82.95pt" }} valign="top">
                        <p>Digital services provider for Users of the Service [9] [10]</p>
                        <p>&nbsp;</p>
                    </td>
                    <td style={{ width: "125.6pt" }} valign="top">
                        <p>
                            <strong>When the User creates an online Account,</strong> the information communicated at
                            the time of its creation is integrated into software dedicated to the management of the
                            Users of the Service after obtaining their consent expressed during the validation of this
                            Privacy Policy at the time of creation.
                        </p>
                    </td>
                    <td style={{ width: "127.8pt" }} valign="top">
                        <p>
                            <strong>Consent</strong>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            Validation of the information notice linked to the Service's Privacy Policy when creating
                            the online Account allows Labrador to obtain the consent of the User. It is confirmed by
                            sending an automatic email with a reminder of the data processing methods and the
                            possibility of opposing them.[11]
                        </p>
                    </td>
                    <td style={{ width: "114.35pt" }} valign="top">
                        <p>
                            The service provider then has access to the data provided by the User when creating their
                            Account, namely their family name, first name and email address as well as any information
                            that may be related to the use of their Account.&nbsp;
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
        <p>
            <strong>We do not offer or sell your Personal Data.</strong>
        </p>
        <p>
            Labrador undertakes not to communicate the Data collected to a third party without obtaining the consent of
            the User, except in cases where a request might be made by a government authority or body, as part of the
            execution of legal or regulatory obligations. In any event, Labrador has ensured the commitment of its
            service providers and subcontractors to comply with the Regulations.[12]
        </p>
        <h3>
            <strong>6.</strong> <strong>Where is the data hosted?</strong>
        </h3>
        <p>Labrador informs the User that their Personal Data is hosted by:</p>
        <p>
            <strong>
                Cyllene
                <br />
                92, rue R&eacute;aumur
                <br />
                75002 Paris
                <br />
            </strong>
            <a href="https://www.groupe-cyllene.com/">https://www.groupe-cyllene.com/</a>
        </p>
        <h3>
            <strong>7.</strong> <strong>Transfer of Personal Data outside the European Union</strong>
        </h3>
        <p>
            Labrador is an international group some of whose subsidiaries are located outside the European Union
            (hereafter "EU"). However, the data collected via the Service is not transferred outside the EU.
        </p>
        <p>
            In the event that the Personal Data are transferred to a country located outside the EU, we undertake to
            inform the User in advance, unless we are exempt from doing so for a legitimate reason or in accordance with
            a specific legal or regulatory obligation.
        </p>
        <p>
            In the event of a transfer of Personal Data outside the EU, we undertake to comply with the appropriate
            guarantees provided for by the GDPR, by implementing standard contractual clauses and by implementing the
            technical and organizational measures necessary to ensure the same level of protection as that required by
            European regulations on personal data.
        </p>
        <h3>
            <strong>8.</strong> <strong>What are Labrador's obligations?</strong>
        </h3>
        <p>As the data controller for the processing defined in this document, Labrador undertakes to: </p>
        <ul>
            <li>
                Implement all appropriate technical and organizational measures to guarantee the confidentiality,
                integrity and security of the Data, including protection against loss, destruction or accidental damage;
            </li>
            <li>
                Raise awareness and train internal staff in the processing of Data, the Regulations and their
                consequences;
            </li>
            <li>Restrict access to User Data to only those duly authorized for this purpose;</li>
            <li>Guarantee all the rights of Users under the conditions defined in Point 9.;</li>
            <li>
                Notify the competent supervisory authority and the Users of any data breach that poses a high risk to
                the rights and freedoms of the Users within seventy-two (72) hours of the discovery of the fraudulent
                intrusion if the Regulations require it;
            </li>
            <li>Respect the data retention periods defined in this document.</li>
        </ul>
        <h3>
            <strong>9.</strong> <strong>What are the Users&rsquo; rights over the data collected?</strong>
        </h3>
        <p>
            In accordance with the applicable Personal Data Regulations and in particular with Articles 15, 16, 17, 18,
            20 and 21 of the GDPR, the User has and may exercise the following rights with respect to Labrador:
        </p>
        <table cellSpacing="0" cellPadding="0">
            <thead>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "152.25pt" }} valign="top">
                        <p>
                            <strong>YOUR RIGHTS</strong>
                        </p>
                    </td>
                    <td style={{ width: "297.75pt" }} valign="top">
                        <p>
                            <strong>WHAT IT MEANS</strong>
                        </p>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "152.25pt" }} valign="top">
                        <p>
                            The right<strong> to be informed</strong>
                        </p>
                    </td>
                    <td style={{ width: "297.75pt" }} valign="top">
                        <p>
                            You have the right to obtain clear, transparent, understandable and easily accessible
                            information about how we use your Personal Data and your rights. This is why we provide the
                            information in this Policy.
                        </p>
                    </td>
                </tr>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "152.25pt" }} valign="top">
                        <p>
                            The right to<strong> access</strong>
                        </p>
                    </td>
                    <td style={{ width: "297.75pt" }} valign="top">
                        <p>
                            You have the right to access the Personal Data we hold about you (subject to certain
                            restrictions).
                        </p>
                    </td>
                </tr>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "152.25pt" }} valign="top">
                        <p>
                            The right to<strong> rectification</strong>
                        </p>
                    </td>
                    <td style={{ width: "297.75pt" }} valign="top">
                        <p>
                            You have the right to request that your Personal Data be rectified if it is inaccurate or
                            out of date and/or completed if it is incomplete or updated.
                        </p>
                    </td>
                </tr>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "152.25pt" }} valign="top">
                        <p>
                            The right to<strong> erasure/right</strong>
                        </p>
                        <p>
                            <strong>to be forgotten</strong>
                        </p>
                    </td>
                    <td style={{ width: "297.75pt" }} valign="top">
                        <p>
                            In certain cases, you have the right to obtain the erasure or deletion of your Personal
                            Data. This is not an absolute right insofar as we may be required to keep your Personal Data
                            for legal reasons.
                        </p>
                    </td>
                </tr>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "152.25pt" }} valign="top">
                        <p>
                            The right to
                            <strong> withdraw consent at any time for data processing based on consent</strong>
                        </p>
                    </td>
                    <td style={{ width: "297.75pt" }} valign="top">
                        <p>
                            You can withdraw your consent to the processing of your data if this processing is based on
                            consent. The withdrawal of this consent does not affect the lawfulness of the processing
                            based on the consent given before the withdrawal of that consent.[13]&nbsp; Please consult
                            the table inserted in the section "What Personal Data do we collect from you and how do we
                            use them? ", and particularly the column "What is the legal basis for processing your
                            Personal Data?" to find out whether our processing is based on consent.
                        </p>
                    </td>
                </tr>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "152.25pt" }} valign="top">
                        <p>
                            The right to<strong> lodge a complaint with a supervisory authority</strong>
                        </p>
                    </td>
                    <td style={{ width: "297.75pt" }} valign="top">
                        <p>
                            You have the right to lodge a complaint with the data protection authority of your country
                            to challenge the practices used in terms of Personal Data protection and respect for
                            privacy.
                        </p>
                        <p>
                            We invite you to contact us using the contact details below before submitting any complaint
                            to the competent data protection authority.
                        </p>
                    </td>
                </tr>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "152.25pt" }} valign="top">
                        <p>
                            The right to<strong> data portability</strong>
                        </p>
                    </td>
                    <td style={{ width: "297.75pt" }} valign="top">
                        <p>
                            You have the right to move, copy or transmit data concerning you from our database to
                            another. This only applies to the data you have provided, if the processing is based on your
                            consent or on a contract and is carried out using automated processes. Please consult the
                            table inserted in the section "What Personal Data do we collect from you and how do we use
                            them? ", and particularly the column "What is the legal basis for processing your Personal
                            Data?&rdquo; to find out whether our processing is contract-based or consent-based.
                        </p>
                    </td>
                </tr>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "152.25pt" }} valign="top">
                        <p>
                            The right to<strong> restriction of processing</strong>
                        </p>
                    </td>
                    <td style={{ width: "297.75pt" }} valign="top">
                        <p>You have the right to request that we restrict the processing of your Personal Data.</p>
                        <p>
                            This right means that the processing of data concerning you is limited, so that we can keep
                            the data, but not use or process them.
                        </p>
                        <p className="!mb-0">
                            This right applies in specific circumstances provided for by the GDPR, namely:
                        </p>
                        <ul>
                            <li>
                                The accuracy of the Personal Data is contested by the data subject (i.e. you), for a
                                period enabling the data controller to verify the accuracy of the Personal Data;
                            </li>
                            <li>
                                The processing is unlawful and the data subject (i.e. you) opposes its erasure and
                                requests the restriction of their use instead;
                            </li>
                            <li>
                                The data controller (i.e. Labrador) no longer needs the Personal Data for the purposes
                                of processing, but they are still necessary for the data subject to establish, exercise
                                or defend legal rights in court;
                            </li>
                            <li>
                                The data subject (i.e. you) has objected to the processing based on the legitimate
                                interests pursued by the controller during the verification as to whether the legitimate
                                reasons pursued by the controller prevail over time over those of the person concerned.
                            </li>
                        </ul>
                    </td>
                </tr>
            </tbody>
        </table>

        <p>
            The User may exercise these rights at any time by contacting the dedicated department by one of the
            following means:
        </p>
        <table cellSpacing="0" cellPadding="0">
            <thead>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "223.9pt" }} valign="top">
                        <p>
                            <strong>
                                <em>By mail:</em>
                            </strong>
                        </p>
                    </td>
                    <td style={{ width: "226.85pt" }} valign="top">
                        <p>
                            <strong>
                                <em>By email:</em>
                            </strong>
                        </p>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "223.9pt" }} valign="top">
                        <p>
                            <strong>Laurent Rouyr&egrave;s</strong>
                        </p>
                        <p>Labrador</p>
                        <p>8, rue Lantiez,</p>
                        <p>75017 Paris</p>
                    </td>
                    <td style={{ width: "226.85pt" }} valign="top">
                        <p>
                            <a href="mailto:contact@labrador-company.com">contact@labrador-company.com</a>
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>

        <p className="!mb-0">The User, to exercise his or her rights, provides Labrador with:</p>
        <ul>
            <li>The origin of the collection of their Personal Data (contact form, for example);</li>
            <li>Their identity and contact details.</li>
        </ul>

        <p>
            In the event of "reasonable doubt" as to the identity of the person making the request (for example, in the
            event of a request made via an unknown email address), and in order to prevent identity theft or risks of
            homonymy, Labrador may request proof of identity to be provided.
        </p>
        <p>
            Labrador has one (1) month from receipt of the request to respond, provided that the request is well founded
            and that it is not manifestly excessive.
        </p>
        <p>The User may also refuse to allow their Personal Data to be used by Labrador at any time.</p>
        <p>To do so, the User is invited to click on the unsubscribe links provided in the emails sent by Labrador.</p>
        <p>
            Users are informed that they may, if they believe that their rights have not been respected, file a
            complaint with the National Commission for Information Technology and Civil Liberties (hereinafter, the
            "CNIL"). Labrador invites the User to go to the following link for more information:
            <a href="https://www.cnil.fr/fr/les-droits-pour-maitriser-vos-donnees-personnelles">
                {" "}
                hhttps://www.cnil.fr/fr/les-droits-pour-maitriser-vos-donnees-personnelles
            </a>
            .
        </p>
        <h3>
            <strong>10.</strong>&nbsp; <strong>Managing cookies</strong>
        </h3>
        <p className="!mb-0">
            A "Cookie" is a text file placed by Labrador on the User&rsquo;s terminal when browsing using the Service.
            The cookie records information relating to the User's browsing using the Service and stores information that
            the User enters during their visit. The use of cookies also makes it possible to offer Users the best user
            experience. The cookies thus placed are used to:
        </p>
        <ul>
            <li>Optimize the ergonomics of the Service and the browsing experience;</li>
            <li>
                Remember preferences so that you do not have to enter or download the same information again when you
                use our Service;
            </li>
            <li>Improve the security and speed of our Service.</li>
        </ul>

        <p>
            For information on specific cookies placed through a given website (partners/third parties), please refer to
            the Cookies table on the partner/third party website.
        </p>
        <p>
            Labrador informs the User to place the following cookies for the following functions<strong>:</strong>
        </p>

        <table cellSpacing="0" cellPadding="0">
            <thead>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "105.3pt" }} valign="top">
                        <p>
                            <strong>Name of the placed cookie</strong>
                        </p>
                    </td>
                    <td style={{ width: "50.5pt" }} valign="top">
                        <p>
                            <strong>Type of placed cookie</strong>
                        </p>
                    </td>
                    <td style={{ width: "143.5pt" }} valign="top">
                        <p>
                            <strong>Origin</strong>
                        </p>
                    </td>
                    <td style={{ width: "100.95pt" }} valign="top">
                        <p>
                            <strong>Functions</strong>
                        </p>
                    </td>
                    <td style={{ width: "50.5pt" }} valign="top">
                        <p>
                            <strong>Retention period on the device</strong>
                        </p>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "105.3pt" }} valign="top">
                        <p>Cookies_consent_status</p>
                    </td>
                    <td style={{ width: "50.5pt" }} valign="top">
                        <p>Functional</p>
                    </td>
                    <td style={{ width: "143.5pt" }} valign="top">
                        <p>&nbsp;</p>
                    </td>
                    <td style={{ width: "100.95pt" }} valign="top">
                        <p className="!mb-0">Optimize the Service experience and ensure its smooth operation:</p>
                        <ul>
                            <li>Analyze Internet user connections to the Service;</li>
                            <li>Establish communication between the web server and the browser;</li>
                            <li>
                                Ensure that the Service is correctly displayed depending on the browser's information.
                            </li>
                        </ul>
                    </td>
                    <td style={{ width: "50.5pt" }} valign="top">
                        <p>1 year</p>
                    </td>
                </tr>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "105.3pt" }} valign="top">
                        <p>Google Analytics</p>
                    </td>
                    <td style={{ width: "50.5pt" }} valign="top">
                        <p>Analytics</p>
                    </td>
                    <td style={{ width: "143.5pt" }} valign="top">
                        <p>Google</p>
                        <p>
                            <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>
                        </p>
                    </td>
                    <td style={{ width: "100.95pt" }} valign="top">
                        <p className="!mb-0">
                            Anonymous analysis of pages dedicated to the Service visited by the User:
                        </p>
                        <ul>
                            <li>Count and monitor the pages visited and the number of visitors;</li>
                            <li>Filter requests from robots;</li>
                            <li>Store anonymous visitor statistics.</li>
                        </ul>
                    </td>
                    <td style={{ width: "50.5pt" }} valign="top">
                        <p>1 year</p>
                    </td>
                </tr>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "105.3pt" }} valign="top">
                        <p>cookieControlPrefs</p>
                    </td>
                    <td style={{ width: "50.5pt" }} valign="top">
                        <p>Functional</p>
                    </td>
                    <td style={{ width: "143.5pt" }} valign="top">
                        <p>&nbsp;</p>
                    </td>
                    <td style={{ width: "100.95pt" }} valign="top">
                        <p>Save the User&rsquo;s cookie consent preferences.</p>
                    </td>
                    <td style={{ width: "50.5pt" }} valign="top">
                        <p>6 months</p>
                    </td>
                </tr>
            </tbody>
        </table>

        <p>
            The User is invited to give their overall consent to the placement of these cookies by clicking on "Accept
            All" in the information banner at the bottom of the page dedicated to the Service during their browsing.
            They may also consent to this on a case-by-case basis using the drop-down menu for each type of cookie and
            associated purposes.
        </p>
        <p>
            The User can also deactivate all cookies by clicking on the "Refuse all" button in the aforementioned
            information banner. However, regarding cookies used to improve the ergonomics and operation of the Service,
            disabling them could prevent you from using certain parts of the Service.
        </p>
        <p className="!mb-0">
            Notwithstanding certain audience measurement cookies, which cannot be configured as they are essential for
            the proper functioning of the Service, the User may object to the placement of cookies directly from his or
            her browser as follows:
        </p>
        <ul>
            <li>
                For Mozilla Firefox: choose the "Tools" menu then "Options" / Click on the "Privacy" icon / Find the
                "Cookie" menu and select the desired options;
            </li>
            <li>
                For Microsoft Internet Explorer: select the "Tools" menu then "Internet Options" / click on the
                "Confidentiality" tab / select the desired level using the slider;
            </li>
            <li>For Op&eacute;ra 6.0 and later versions: select the menu "File"&gt; "Preferences" / "Privacy";</li>
            <li>
                For Safari: choose the menu "Edit"&gt; "Preferences" / "Security". Click on "Show cookies" and select
                the cookies concerned, then click on "Clear" / "Done";
            </li>
            <li>
                For Google Chrome: Click on "Tools"&gt; "Options"&gt; "Advanced options", "Confidentiality", "Show
                cookies" then delete the files concerned. Finally, click on "Close".
            </li>
        </ul>
        <p>
            Users are informed that the maximum retention period for this information on their device is one (1) year.
        </p>
        <p>
            The User may also oppose the placement of cookies by going to the advertising cookies management platform
            offered by advertising professionals at the following address:
        </p>
        <p>
            <a href="http://www.youronlinechoices.com/fr/controler-ses-cookies/" aria-invalid="true">
                http://www.youronlinechoices.com/fr/controler-ses-cookies/
            </a>{" "}
            and by following the instructions given there.
        </p>
        <p>
            In any event, Users who have expressed their choices in terms of cookies will no longer be presented with
            the information banner during their next visits, for a period of six (6) months. The User may, however, have
            access to their choices and modify them at any time, thanks to the tab<em> </em>"Configure my cookies" which
            will reopen the stored information banner.
        </p>
        <h3>
            <strong>11.</strong>&nbsp; <strong>Property</strong>
        </h3>
        <p>
            The Service and its various elements are protected by intellectual and industrial property law. Any
            representation, any reproduction and, more generally, any exploitation for payment or free of charge, for
            any purpose whatsoever, by any means whatsoever, of all or part of the Service (in particular its general
            structure, photographs, information, icons, acronyms, logos, graphics, brands, illustrations, texts
            appearing therein, without this list being exhaustive), for commercial purposes or not, without the prior
            written authorization of Labrador is forbidden. The information contained via the Service is strictly
            reserved for personal, private and free use. Any use contrary to this clause constitutes an act of
            counterfeiting under criminal and civil law.
        </p>
        <h3>
            <strong>12.</strong>&nbsp; <strong>Hypertext links</strong>
        </h3>
        <p>
            Through this Service, the User can access sites designed and managed under the responsibility of third
            parties.
        </p>
        <p>
            The User is informed that Labrador has no control over the content of said sites. In addition, Labrador
            declines all responsibility with regard to their content and the use made by any third party of the
            information contained therein.
        </p>
        <h3>
            <strong>13.</strong>&nbsp; <strong>Integrity &ndash; Technical Failures</strong>
        </h3>
        <p>
            Labrador reserves the right to temporarily interrupt access to the Digital URD Service in order to carry out
            technical, improvement and maintenance works to ensure its proper functioning or to repair a breakdown.
        </p>
        <p>
            The User is informed that the security and integrity of Internet communications cannot be guaranteed.
            Labrador undertakes to make its best efforts to warn the User of any difficulty in accessing the Service or
            any interruption in the distribution of the online Universal Registration Document, without any performance
            obligation.
        </p>
        <h3>
            <strong>14.</strong>&nbsp; <strong>Law and jurisdiction</strong>
        </h3>
        <p>
            The applicable law is French law, the competent courts are the French courts in compliance with the rules of
            the French Code of Civil Procedure.
        </p>
        <h3>
            <strong>15.</strong>&nbsp; <strong>Changes</strong>
        </h3>
        <p>
            This Policy may be modified or supplemented at any time by Labrador for the purposes of updating in
            accordance with changes in the legislation and regulations in force, subject to informing the User thereof.
            In case of doubt, the latest version is the one on the page dedicated to the Service.
        </p>
        <p>
            However, Users&rsquo; Personal Data will always be processed in accordance with the policy in force at the
            time of their collection, unless a mandatory legal requirement were to provide otherwise and be applied
            retroactively.
        </p>
        <h3>
            <strong>16.</strong>&nbsp; <strong>Contact</strong>
        </h3>
        <p className="!mb-0">
            For any question relating to the collection or processing of the data collected, the User is invited to
            contact Labrador:
        </p>
        <ul>
            <li>By telephone: 00 33 (0)153 063080;</li>
            <li>By mail: Labrador, 8, rue Lantiez, 75017 Paris;</li>
            <li>
                By email: <a href="mailto:contact@labrador-company.com">contact@labrador-company.com</a>
            </li>
        </ul>

        <p>
            Any question will always be received with kindness!<strong> </strong>
        </p>
        <p>
            <strong>Document dated: April 26, 2021</strong>
        </p>
        <p>&nbsp;</p>
        <p>
            To obtain further information, you can visit{" "}
            <a href="https://quadient.fr.digital-report.net/en/data-protection-policy">QUADIENT SA</a> data protection
            policy.
        </p>
    </div>
);

export default PrivacyPolicy;
