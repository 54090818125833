const TermsAndConditionsFR = (
    <div>
        <p>
            Le Groupe Labrador (ci-apr&egrave;s, &laquo; <em>Labrador</em> &raquo;) met &agrave; disposition de ses
            Utilisateurs le service en ligne de consultation de Document d&rsquo;enregistrement universel
            d&rsquo;Entreprise, URD Digital (ci-apr&egrave;s, le &laquo; <em>Service</em> &raquo;). Il leur offre la
            possibilit&eacute; de consulter ce document mais &eacute;galement d&rsquo;ouvrir un compte en ligne afin
            d&rsquo;acc&eacute;der &agrave; d&rsquo;autres fonctionnalit&eacute;s telles que l&rsquo;annotation ou
            l&rsquo;ajout de commentaires.&nbsp;
        </p>
        <p>
            Labrador indique dans ce pr&eacute;sent document (ci-apr&egrave;s, les &laquo;{" "}
            <strong>
                <em>Conditions d&rsquo;utilisation</em>
            </strong>{" "}
            &raquo;) les modalit&eacute;s d&rsquo;utilisation du Service propos&eacute;.
        </p>
        <p>
            Labrador porte une attention toute particuli&egrave;re au traitement respectueux des donn&eacute;es
            personnelles de ses Utilisateurs et respecte &agrave; ce titre le R&egrave;glement (UE) 2016/679 (dit
            &laquo; RGPD &raquo;), ainsi que de la loi n&deg; 2018-493 du 20 juin 2018 relative &agrave; la protection
            des donn&eacute;es personnelles, qui a modifi&eacute; la loi n&deg; 78-17 du 6 janvier 1978 relative
            &agrave; l'informatique, aux fichiers et aux libert&eacute;s (ci-apr&egrave;s, pris de mani&egrave;re
            globale, la &laquo;{" "}
            <strong>
                <em>R&eacute;glementation</em>
            </strong>{" "}
            &raquo;).
        </p>
        <p>
            Les Conditions d&rsquo;utilisation sont susceptibles d&rsquo;&ecirc;tre modifi&eacute;es par Labrador aux
            fins notamment de mises &agrave; jour en fonction des &eacute;volutions de la R&eacute;glementation, sous
            r&eacute;serve d&rsquo;en informer pr&eacute;alablement ses utilisateurs. En cas de doute,
            l&rsquo;Utilisateur est invit&eacute; &agrave; consulter leur derni&egrave;re version disponible via le
            Service.&nbsp;
        </p>
        <h3>
            <strong>D&Eacute;FINITIONS</strong>
        </h3>
        <p>
            Les mots ci-apr&egrave;s d&eacute;finis, commen&ccedil;ant par une lettre majuscule, pris
            indiff&eacute;remment au singulier ou au pluriel, auront la signification suivante :
        </p>
        <p>
            &laquo;{" "}
            <strong>
                <em>Service</em>
            </strong>{" "}
            &raquo; : d&eacute;signe le service propos&eacute; par Labrador, de consultation en ligne du Document
            d&rsquo;enregistrement universel (URD) de toute Entreprise y ayant souscrit, d&eacute;nomm&eacute; URD
            Digital. Il est d&eacute;ploy&eacute; sur le Site internet de ladite Entreprise, accessible sur tous les
            navigateurs.
        </p>
        <p>
            &laquo;{" "}
            <strong>
                <em>Donn&eacute;e</em>
            </strong>{" "}
            &raquo; : d&eacute;signe toute information se rapportant &agrave; une personne physique identifi&eacute;e ou
            identifiable, c&rsquo;est-&agrave;-dire qui peut &ecirc;tre identifi&eacute;e, directement ou indirectement,
            notamment par r&eacute;f&eacute;rence &agrave; un identifiant, tel qu&rsquo;un nom, un num&eacute;ro
            d&rsquo;identification, des donn&eacute;es de localisation, un identifiant en ligne, ou &agrave; un ou
            plusieurs &eacute;l&eacute;ments sp&eacute;cifiques propres &agrave; son identit&eacute; physique,
            physiologique, g&eacute;n&eacute;tique, psychique, &eacute;conomique, culturelle ou sociale.
        </p>
        <p>
            &laquo;{" "}
            <strong>
                <em>Compte</em>
            </strong>{" "}
            &raquo; : d&eacute;signe le compte personnel cr&eacute;&eacute; par l&rsquo;Utilisateur du Service au moyen
            d&rsquo;une connexion au Service et lui permettant d&rsquo;acc&eacute;der &agrave; certaines
            fonctionnalit&eacute;s li&eacute;es &agrave; la consultation du Document universel d&rsquo;enregistrement.
        </p>
        <p>
            &laquo;{" "}
            <strong>
                <em>Document d&rsquo;enregistrement universel</em>
            </strong>
            &raquo; ou &laquo;{" "}
            <strong>
                <em>URD </em>
            </strong>
            &raquo; : d&eacute;signe le document de r&eacute;f&eacute;rence actualis&eacute; chaque ann&eacute;e que les
            soci&eacute;t&eacute;s c&ocirc;t&eacute;es ont l&rsquo;obligation d&rsquo;&eacute;tablir et de
            d&eacute;poser aupr&egrave;s de l&rsquo;Autorit&eacute; des march&eacute;s financiers, l&rsquo;AMF.
        </p>
        <p>
            &laquo;{" "}
            <strong>
                <em>Utilisateur</em>
            </strong>{" "}
            &raquo; : d&eacute;signe toute personne physique, qui utilise le Service propos&eacute; par Labrador,
            consulte le Document d&rsquo;enregistrement universel, voire proc&egrave;de &agrave; la cr&eacute;ation
            d&rsquo;un compte et accepte les Conditions d&rsquo;utilisation.
        </p>
        <h3>
            <strong>INSCRIPTION AU SERVICE ET DONNEES COLLECTEES</strong>
        </h3>
        <p>
            L&rsquo;utilisation du Service en ligne n&rsquo;est pas conditionn&eacute;e &agrave; la cr&eacute;ation
            d&rsquo;un compte. L&rsquo;acc&egrave;s &agrave; l&rsquo;URD en question est d&rsquo;ailleurs public et le
            document est consultable &agrave; tout moment. L&rsquo;Utilisateur se connecte au Service, lit les
            Conditions d&rsquo;utilisation et les accepte.
        </p>
        <p>
            Toutefois, l&rsquo;inscription au Service, permettant d&rsquo;acc&eacute;der &agrave; d&rsquo;autres
            fonctionnalit&eacute;s, s&rsquo;effectue via la cr&eacute;ation d&rsquo;un compte en ligne.
        </p>
        <p className="!mb-0">
            Pour ce faire, l&rsquo;Utilisateur est invit&eacute; &agrave; communiquer de mani&egrave;re obligatoire les
            Donn&eacute;es suivantes :
        </p>
        <ul>
            <li>Son pr&eacute;nom ;</li>
            <li>Son nom ;</li>
            <li>Son adresse e-mail.</li>
        </ul>
        <p>
            Une fois son Compte cr&eacute;&eacute;, un email de confirmation d&rsquo;inscription sera automatiquement
            g&eacute;n&eacute;r&eacute; et envoy&eacute; sur l&rsquo;adresse renseign&eacute;e comprenant les
            pr&eacute;sentes Conditions d&rsquo;utilisation
        </p>
        <p>
            Le Service permet &agrave; l&rsquo;Utilisateur d&rsquo;annoter le document ou d&rsquo;y laisser des
            commentaires et de les sauvegarder afin de les consulter d&egrave;s qu&rsquo;il se connecte.
        </p>
        <p>
            L&rsquo;Utilisateur est inform&eacute; que Labrador ne collecte pas le contenu de ses commentaires ou
            annotations mais uniquement leur localisation sur le document.
        </p>
        <p>
            L&rsquo;Utilisateur s&rsquo;engage &agrave; communiquer, au moment de son inscription, des Donn&eacute;es
            exactes, compl&egrave;tes et &agrave; jour, excluant tout propos contraire &agrave; la l&eacute;gislation en
            vigueur, &agrave; l&rsquo;ordre public et aux bonnes m&oelig;urs, n&rsquo;usurpant pas
            l&rsquo;identit&eacute; d&rsquo;un tiers ou contrefaisant les droits de propri&eacute;t&eacute;
            intellectuelle d&rsquo;un tiers.
        </p>
        <p>
            En outre, l&rsquo;Utilisateur peut valablement refuser de communiquer les Donn&eacute;es sollicit&eacute;es,
            mais est d&ucirc;ment inform&eacute; qu&rsquo;il ne pourra b&eacute;n&eacute;ficier de toutes les
            fonctionnalit&eacute;s du Service le cas &eacute;ch&eacute;ant.
        </p>
        <p>
            L&rsquo;Utilisateur est inform&eacute; que des technologies de tra&ccedil;age dites &laquo; cookies &raquo;
            sont &eacute;galement plac&eacute;es sur les terminaux des Utilisateurs lors de leur navigation via le
            Service. Les Donn&eacute;es collect&eacute;es &agrave; travers ces cookies et leurs modalit&eacute;s de
            gestion sont plus amplement d&eacute;crites &agrave; l&rsquo;article 14 des pr&eacute;sentes.
        </p>
        <h3>
            <strong>DESCRIPTION DES FONCTIONNALIT&Eacute;S DE L&rsquo;APPLICATION</strong>
        </h3>
        <p>Le Service offre les fonctionnalit&eacute;s suivantes :</p>
        <table border={0} cellSpacing="0" cellPadding="0">
            <thead>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "141.05pt" }} valign="top">
                        <p>
                            <strong>
                                <em>Fonctionnalit&eacute;s :</em>
                            </strong>
                        </p>
                    </td>
                    <td style={{ width: "309.7pt" }} valign="top">
                        <p>
                            <strong>
                                <em>Permettant :</em>
                            </strong>
                        </p>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "141.05pt" }} valign="top">
                        <p>Consulter l&rsquo;URD</p>
                    </td>
                    <td style={{ width: "309.7pt" }} valign="top">
                        <p>
                            De proposer &agrave; chaque Utilisateur la consultation de l&rsquo;URD de l&rsquo;Entreprise
                            ayant souscrit &agrave; l&rsquo;URD Digital.
                        </p>
                    </td>
                </tr>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "141.05pt" }} valign="top">
                        <p>Annoter ou commenter l&rsquo;URD</p>
                    </td>
                    <td style={{ width: "309.7pt" }} valign="top">
                        <p>
                            A l&rsquo;Utilisateur d&rsquo;&eacute;mettre toute remarque ou de souligner toute
                            information importante relative au document qu&rsquo;il consulte.
                        </p>
                        <p>
                            Consulter &agrave; tout moment les annotations ou commentaires effectu&eacute;s lors de la
                            revue dudit document.
                        </p>
                    </td>
                </tr>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "141.05pt" }} valign="top">
                        <p>
                            Rentrer en contact avec la Soci&eacute;t&eacute; pour laquelle l&rsquo;URD a
                            &eacute;t&eacute; &eacute;dit&eacute;
                        </p>
                    </td>
                    <td style={{ width: "309.7pt" }} valign="top">
                        <p>
                            Le Service met &agrave; disposition des Utilisateurs un formulaire de contact afin de
                            renseigner tout type de demande qui sera transmise &agrave; la Soci&eacute;t&eacute;
                            concern&eacute;e afin qu&rsquo;elle puisse y r&eacute;pondre et recontacter
                            l&rsquo;Utilisateur dans les meilleurs d&eacute;lais.
                        </p>
                    </td>
                </tr>
                <tr style={{ height: "15.0pt" }}>
                    <td style={{ width: "141.05pt" }} valign="top">
                        <p>Recevoir l&rsquo;URD au format papier</p>
                    </td>
                    <td style={{ width: "309.7pt" }} valign="top">
                        <p>A l&rsquo;Utilisateur de recevoir par courrier postal la version papier de l&rsquo;URD.</p>
                    </td>
                </tr>
            </tbody>
        </table>
        <h3>
            <strong>OBLIGATIONS DE L&rsquo;UTILISATEUR</strong>
        </h3>
        <p>
            L&rsquo;Utilisateur s&rsquo;engage &agrave; utiliser les fonctionnalit&eacute;s du Service
            conform&eacute;ment &agrave; leur destination propre et &agrave; ne pas en d&eacute;tourner les
            finalit&eacute;s par un usage inappropri&eacute;.
        </p>
        <p>
            L&rsquo;Utilisateur s&rsquo;engage en outre express&eacute;ment &agrave; ne pas (i) transmettre un virus
            informatique, vers, cheval de Troie, bombe logique ou tout autre fichier, programme ou forme de code
            malicieux ou malveillant, destin&eacute; &agrave; endommager, interrompre, suspendre, d&eacute;truire et/ou
            limiter les fonctionnalit&eacute;s de tout &eacute;quipement informatique ou de
            t&eacute;l&eacute;communication, (ii) usurper l&rsquo;identit&eacute; d&rsquo;une autre personne, d&rsquo;un
            Utilisateur ou d&rsquo;un employ&eacute; ou d&rsquo;un repr&eacute;sentant de Labrador, (iii) utiliser des
            outils d&rsquo;exploration en profondeur de donn&eacute;es, des robots ou tout autre outil similaire de
            collecte et d&rsquo;extraction de donn&eacute;es relatives au Service, (iv) entraver le fonctionnement du
            Service, des serveurs ou des connexions de r&eacute;seau du Service, (vi) enfreindre les exigences,
            proc&eacute;dures, politiques ou r&egrave;glements relatifs aux r&eacute;seaux connect&eacute;s au Service,
            (vii) modifier, adapter, sous-licencier, traduire, vendre, effectuer l&rsquo;ing&eacute;nierie inverse,
            d&eacute;compiler ou d&eacute;sassembler tout ou partie du Service, (viii) essayer d&rsquo;acc&eacute;der
            sans autorisation au Service, (ix) et/ou utiliser le Service pour envoyer ou transmettre tout contenu qui
            pourrait porter pr&eacute;judice ou d&eacute;crier Labrador ou la Soci&eacute;t&eacute; pour laquelle le
            l&rsquo;URD a &eacute;t&eacute; &eacute;dit&eacute; de quelque mani&egrave;re que ce soit et (x) enfreindre
            de toute autre mani&egrave;re les droits de tiers ou les lois et r&egrave;glements en vigueur.
        </p>
        <p>
            En cas de violation par l&rsquo;Utilisateur de l&rsquo;une de ses obligations stipul&eacute;es dans les
            pr&eacute;sentes, Labrador se r&eacute;serve le droit de suspendre ou r&eacute;silier le Compte de
            l&rsquo;Utilisateur dans les conditions de l&rsquo;article 6 des pr&eacute;sentes.
        </p>
        <h3>
            <strong>PROPRI&Eacute;T&Eacute; INTELLECTUELLE</strong>
        </h3>
        <p>
            Le Service et tous les &eacute;l&eacute;ments qui la composent, tels que, notamment, les logiciels, les
            codes, les bases de donn&eacute;es, les textes, documentation, dessins, logos, images, graphiques, ou tout
            autre contenu (ci-apr&egrave;s, les &laquo;{" "}
            <strong>
                <em>El&eacute;ments</em>
            </strong>{" "}
            &raquo;) sont la propri&eacute;t&eacute; exclusive de Labrador ou de l&rsquo;Entreprise pour laquelle
            l&rsquo;URD est &eacute;dit&eacute;. Les &Eacute;l&eacute;ments sont prot&eacute;g&eacute;s par les
            l&eacute;gislations fran&ccedil;aises et internationales relatives aux droits de la propri&eacute;t&eacute;
            intellectuelle (droit d&rsquo;auteur et droit des marques notamment) et sont susceptibles de contenir des
            secrets commerciaux ou des informations confidentielles prot&eacute;g&eacute;es. L&rsquo;Utilisateur accepte
            par les pr&eacute;sentes de ne pas &ocirc;ter et/ou supprimer les mentions relatives au droit d&rsquo;auteur
            et &agrave; tout autre droit de propri&eacute;t&eacute; intellectuelle figurant sur le Service.
        </p>
        <p>
            Il est convenu que les pr&eacute;sentes Conditions d&rsquo;utilisation ne conc&egrave;dent aucun droit
            &agrave; l&rsquo;Utilisateur sur les marques, le droit d&rsquo;auteur ainsi que les dessins et
            mod&egrave;les dont Labrador et ses conc&eacute;dants et partenaires sont titulaires, ni sur aucun
            &Eacute;l&eacute;ment hormis la licence ci-apr&egrave;s conc&eacute;d&eacute;e.
        </p>
        <p>
            Sous r&eacute;serve du respect des stipulations des pr&eacute;sentes, l&rsquo;Utilisateur est uniquement
            autoris&eacute; &agrave; acc&eacute;der et &agrave; utiliser le Service pour ses besoins propres et se voit
            conf&eacute;rer un droit d&rsquo;acc&egrave;s et d&rsquo;utilisation limit&eacute;, non-exclusif,
            non-transf&eacute;rable et insusceptible de sous-licence, et permet &agrave; l&rsquo;Utilisateur
            d&rsquo;afficher tout ou partie des fonctionnalit&eacute;s du Service, pour toute la dur&eacute;e
            d&rsquo;existence de son Compte.
        </p>
        <p>
            L&rsquo;Utilisateur est et demeure propri&eacute;taire, ou &agrave; tout le moins responsable, de
            l&rsquo;ensemble des Donn&eacute;es et contenus qu&rsquo;il met &agrave; disposition, transmet ou stocke sur
            son <em>smartphone</em> via le Service et dans le cadre des pr&eacute;sentes (informations personnelles,
            photographies etc.).
        </p>
        <h3>
            <strong>DUR&Eacute;E DE L&rsquo;INSCRIPTION ET R&Eacute;SILIATION</strong>
        </h3>
        <p>L&rsquo;inscription au Service est conclue pour une dur&eacute;e ind&eacute;termin&eacute;e.</p>
        <p>
            L&rsquo;Utilisateur peut &agrave; tout moment r&eacute;silier son Compte sur le Service en se rendant dans
            son espace personnel et en cliquant sur le lien &laquo; Supprimer mon compte &raquo;{" "}
            <strong>[&agrave; mettre en place]</strong>.&nbsp; Si l&rsquo;Utilisateur a cl&ocirc;tur&eacute; son Compte,
            il ne lui sera plus possible d&rsquo;acc&eacute;der aux annotations ou commentaires qu&rsquo;il aura
            effectu&eacute; sur l&rsquo;URD et est inform&eacute; que ses Donn&eacute;es pr&eacute;c&eacute;demment
            charg&eacute;es par le biais de son Compte (notamment, ses prises de notes) seront conserv&eacute;es pendant
            un d&eacute;lai de 1 an.
        </p>
        <p>
            En cas de manquement par l&rsquo;Utilisateur &agrave; l&rsquo;une quelconque de ses obligations au titre des
            Conditions d&rsquo;utilisation, Labrador adressera une notification &agrave; l&rsquo;Utilisateur par email
            le mettant en demeure de rem&eacute;dier &agrave; ce manquement. Si la notification est rest&eacute;e sans
            effet pendant un d&eacute;lai de huit (8) jours calendaires ou si l&rsquo;Utilisateur commet un nouveau
            manquement dans ce d&eacute;lai, Labrador se r&eacute;serve le droit de suspendre son acc&egrave;s au Compte
            ou de r&eacute;silier imm&eacute;diatement et de plein droit son Compte par notification adress&eacute;e par
            voie d&rsquo;email, sans pr&eacute;judice de tout autre droit et recours.
        </p>
        <p>
            En cas de r&eacute;siliation, par l&rsquo;Utilisateur ou Labrador, conform&eacute;ment au pr&eacute;sent
            article, l&rsquo;Utilisateur ne pourra plus acc&eacute;der aux fonctionnalit&eacute;s du Service et &agrave;
            son Compte qui sera cl&ocirc;tur&eacute;.
        </p>
        <p>
            Toutes les limitations de garantie et de responsabilit&eacute; pr&eacute;vues aux pr&eacute;sentes
            Conditions d&rsquo;utilisation resteront en vigueur apr&egrave;s la r&eacute;siliation.
            <br />
        </p>
        <h3>
            <strong>DROIT APPLICABLE ET R&Eacute;SOLUTION DES LITIGES</strong>
        </h3>
        <p>
            Si l&rsquo;une des stipulations des Conditions d&rsquo;utilisation s&rsquo;av&eacute;rait nulle, non
            opposable, contraire &agrave; la l&eacute;gislation ou non applicable pour quelque raison, il est convenu
            que la clause litigieuse ne pourra alt&eacute;rer les autres stipulations qui conservent leur plein effet.
        </p>
        <p>
            Toute difficult&eacute; relative &agrave; l&rsquo;ex&eacute;cution, l&rsquo;interpr&eacute;tation ou la
            validit&eacute; des Conditions d&rsquo;utilisation, qui n&rsquo;aurait pas pu &ecirc;tre
            r&eacute;gl&eacute;e pr&eacute;alablement &agrave; l&rsquo;amiable avec Labrador, est soumise &agrave; la
            loi fran&ccedil;aise et aux tribunaux comp&eacute;tents dans les conditions du droit commun et du Code de
            proc&eacute;dure civile. Pour toute r&eacute;clamation relative &agrave; la gestion de ses Donn&eacute;es,
            l&rsquo;Utilisateur peut saisir la CNIL. Pour conna&icirc;tre la politique de gestion et de
            confidentialit&eacute; de ses donn&eacute;es personnelles, l&rsquo;Utilisateur du Service est invit&eacute;
            &agrave; consulter la page suivante : [
            <strong>ins&eacute;rer adresse d&eacute;di&eacute;e &agrave; la politique de confidentialit&eacute;</strong>
            ].
        </p>

        <h3>
            <strong>CONTACTEZ LABRADOR !</strong>
        </h3>
        <p className="!mb-0">
            Pour toute question relative &agrave; la gestion de vos Donn&eacute;es ou sur les modalit&eacute;s
            d&rsquo;utilisation du Service, n&rsquo;h&eacute;sitez pas &agrave; contacter Labrador :
        </p>
        <ul>
            <li>
                Par email, &agrave; l&rsquo;adresse :{" "}
                <a href="mailto:contact@labrador-company.com">contact@labrador-company.com</a> ;
            </li>
            <li>Par courrier, &agrave; l&rsquo;adresse : Labrador, 8 rue Lantiez, 75017 Paris ;</li>
            <li>Par t&eacute;l&eacute;phone au num&eacute;ro : 01.53.06.30.80.</li>
        </ul>
        <p>Toutes les questions seront les bienvenues !</p>
        <br />
        <br />
        <p>
            <strong>Derni&egrave;re version des Conditions d&rsquo;utilisation : 23 avril 2021</strong>
        </p>
    </div>
);

export default TermsAndConditionsFR;
