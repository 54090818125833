import * as React from "react";
import { changeLanguage } from "i18next";
import { useTranslation } from "react-i18next";
import { LanguageContext, SupportedLanguages } from "~/providers/LanguageProvider";
import { Popover } from "@headlessui/react";
import Icon from "~/ui/Icon/Icon";

const LanguageSwitcher: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const region = import.meta.env.VITE_REGION || "us";
    const langs: Array<SupportedLanguages> = ["en", "fr"];
    const { language, setLanguage } = React.useContext(LanguageContext);

    const changeLang = (lang: SupportedLanguages) => {
        changeLanguage(lang);
        setLanguage(lang);
    };

    return (
        region != "us" && (
            <Popover className="relative">
                <Popover.Button className="flex items-center uppercase text-foreground-dark font-bold p-4 gap-x-3 focus:outline-none">
                    <img src={`/lang/${language}.png`} className="rounded-full h-[24px] w-[24px] mr-1" />
                    {language}
                    <Icon size="md" className="!text-foreground-dark">
                        arrow_drop_down
                    </Icon>
                </Popover.Button>
                <Popover.Panel className="absolute flex flex-col bg-white p-2 rounded-2xl shadow-md right-0 w-max">
                    {langs.map((lang: SupportedLanguages) => {
                        return (
                            <button
                                key={lang}
                                onClick={() => changeLang(lang)}
                                className="flex text-foreground-dark items-center gap-x-3 p-2 min-w-[150px]"
                            >
                                <img src={`/lang/${lang}.png`} className="rounded-full h-[24px] w-[24px]" />
                                <div className="grow text-left">{t(`languages.${lang}`)}</div>
                                <Icon className={lang === language ? "visible" : "invisible"}>check</Icon>
                            </button>
                        );
                    })}
                </Popover.Panel>
            </Popover>
        )
    );
};

export default LanguageSwitcher;
