import axios from "axios";

const apiService = axios.create({
    baseURL: import.meta.env.VITE_API_HOST,
    headers: {
        region: import.meta.env.VITE_REGION,
    },
});

// Function to update the authorization header
export const setAuthToken = (token: string) => {
    if (token) {
        apiService.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
        delete apiService.defaults.headers.common["Authorization"];
    }
};

// Initialize the token from localStorage on app startup
let token = localStorage.getItem("_t") as string;
if (token) {
    token = token.replace(/["']/g, "");
    setAuthToken(token);
}

export default apiService;
