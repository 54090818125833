const LegalNoticeFR = (
    <div>
        <p>
            Le Site urd-digital.com propose un service de mise &agrave; disposition en ligne et de consultation de
            Document d&rsquo;enregistrement universel d&eacute;nomm&eacute; URD Digital (ci-apr&egrave;s le &laquo;
            Service &raquo;).
        </p>
        <p>
            L&rsquo;acc&egrave;s au Service, sa consultation et son utilisation sont subordonn&eacute;s &agrave;
            l&rsquo;acceptation sans r&eacute;serve des Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation et la
            Politique de Confidentialit&eacute; du Service.
        </p>
        <h3>
            <strong>&Eacute;diteur du Site &ndash; H&eacute;bergeurs</strong>
        </h3>
        <p className="!mb-0">
            L&rsquo;&eacute;diteur du Site Internet est le Groupe Labrador (ci-apr&egrave;s &laquo; Labrador &raquo;) :
        </p>
        <ul>
            <li>S.A.S. au capital 100.000 Euros,</li>
            <li>Si&egrave;ge social : 8 rue Lantiez, 75017 &ndash; Paris,</li>
            <li>Immatricul&eacute;e au Registre du Commerce de Paris sous le num&eacute;ro 3 84362182,</li>
            <li>Num&eacute;ro de TVA intracommunautaire : FR28384362182.</li>
        </ul>
        <p>Le Directeur de Publication du Site Internet est Mr Laurent Rouyr&egrave;s.</p>
        <p>
            Le Site Internet est h&eacute;berg&eacute; sur les serveurs de la soci&eacute;t&eacute; par la
            soci&eacute;t&eacute;
        </p>
        <p>
            <strong>
                Cyllene
                <br />
                92, rue R&eacute;aumur
                <br />
                75002 Paris
                <br />
            </strong>
            <a href="https://www.google.com/url?q=https://www.groupe-cyllene.com/&amp;sa=D&amp;source=hangouts&amp;ust=1619522237924000&amp;usg=AFQjCNHD_zMYYfI6oSCn-tPRsDoNsgbuDQ">
                https://www.groupe-cyllene.com/
            </a>
        </p>
        <h3>
            <strong>Conception - R&eacute;f&eacute;rencement &ndash; Maintenance</strong>
        </h3>
        <p>
            Labrador Company
            <br />
            8 rue lantiez, 75017 Paris &ndash; France
            <br />
            <a href="http://www.labrador-company.com/">www.labrador-company.com</a>
            <br />
        </p>
        <h3>
            <strong>D&eacute;charge de responsabilit&eacute;s</strong>
        </h3>
        <p>
            Les informations contenues par ce Service ne constituent aucunement des offres commerciales
            d&rsquo;accompagnement ou de services et ne sauraient avoir une valeur contractuelle.
        </p>
        <p>
            Le contenu des pages, diffus&eacute; &agrave; titre purement informatif, ne saurait engager la
            responsabilit&eacute; de l&rsquo;&eacute;diteur du site urd-digital.com.
        </p>
        <p>
            Le Service est accessible &agrave; tous les utilisateurs du r&eacute;seau internet 24/24h, 7/7j, sauf
            interruption, programm&eacute;e ou non, par Labrador ou ses prestataires, pour les besoins de sa maintenance
            et/ou de s&eacute;curit&eacute; ou cas de force majeure.
        </p>
        <p>
            Labrador ne saurait &ecirc;tre tenue responsable de tout pr&eacute;judice, quelle qu&rsquo;en soit la
            nature, r&eacute;sultant d&rsquo;une indisponibilit&eacute; du Service.
        </p>
        <p>
            Labrador ne garantit pas que le Service sera exempt d&rsquo;anomalies, erreurs ou bugs, ni que le Service
            fonctionnera sans panne, ni interruption. Labrador peut &agrave; cet &eacute;gard d&eacute;terminer
            librement et &agrave; son enti&egrave;re discr&eacute;tion toute p&eacute;riode
            d&rsquo;indisponibilit&eacute; du Service.
        </p>
        <p>
            Labrador ne peut non plus &ecirc;tre tenue responsable de probl&egrave;mes de transmission de
            donn&eacute;es, de connexion ou d&rsquo;indisponibilit&eacute; du r&eacute;seau.
        </p>
        <p>
            Labrador se r&eacute;serve le droit de faire &eacute;voluer le Service, pour des raisons techniques ou
            commerciales. Lorsque ces modifications n&rsquo;alt&egrave;rent pas les conditions de la fourniture des
            services, de mani&egrave;re substantielle et n&eacute;gative, l&rsquo;Utilisateur est inform&eacute; des
            modifications intervenues, sans que son acceptation ne soit requise.
        </p>
        <p className="!mb-0">Labrador d&eacute;cline d&rsquo;ailleurs toute responsabilit&eacute; : </p>
        <ul>
            <li>
                En cas d&rsquo;impossibilit&eacute; momentan&eacute;e d&rsquo;acc&egrave;s au Service en raison de
                maintenance ou de probl&egrave;mes techniques et ce quelles qu&rsquo;en soient l&rsquo;origine et la
                provenance ou d&rsquo;actualisation de la documentation disponible ;
            </li>
            <li>
                En cas de dommages directs ou indirects caus&eacute;s &agrave; l&rsquo;Utilisateur, quelle qu&rsquo;en
                soit la nature, r&eacute;sultant du contenu, de l&rsquo;acc&egrave;s, ou de l&rsquo;utilisation du
                Service (et/ou du Site qui lui est li&eacute;). En cas d&rsquo;utilisation anormale ou d&rsquo;une
                exploitation illicite du Service, seul l&rsquo;Utilisateur est responsable des dommages caus&eacute;s
                aux tiers et des cons&eacute;quences des r&eacute;clamations ou actions qui pourraient en
                d&eacute;couler. L&rsquo;Utilisateur renonce &agrave; ce titre &agrave; exercer tout recours contre
                Labrador dans le cadre de poursuites diligent&eacute;es par un tiers &agrave; son encontre du fait de
                l&rsquo;utilisation et/ou de l&rsquo;exploitation illicite du Service.
            </li>
        </ul>
        <p>
            L&rsquo;Utilisateur naviguant via le Service et/ou r&eacute;pondant aux formulaires de contact est
            invit&eacute; &agrave; se r&eacute;f&eacute;rer &agrave; la Politique de Confidentialit&eacute; du Service.
        </p>
    </div>
);

export default LegalNoticeFR;
