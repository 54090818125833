import { ReactNode, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "~/providers/LanguageProvider";
import DocumentModal from "./DocumentModal";

import documentListUS from "~/features/LegalDocuments/US";
import documentListFR from "~/features/LegalDocuments/FR";

interface Document {
    title: string;
    content: ReactNode | string;
}

const DocumentLinks = () => {
    const { language } = useContext(LanguageContext);
    const { t } = useTranslation();
    const REGION = import.meta.env.VITE_REGION;
    const documentList = REGION === "us" ? documentListUS : documentListFR;
    const [document, setDocument] = useState<Document>();
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <div className="flex gap-4">
                {documentList[language].map((doc: Document, index: number) => {
                    return (
                        <button
                            key={index}
                            onClick={() => {
                                setDocument(doc);
                                setShowModal(true);
                            }}
                        >
                            {t(doc.title)}
                        </button>
                    );
                })}
            </div>

            {showModal && <DocumentModal {...document} closeFn={() => setShowModal(false)} />}
        </>
    );
};

export default DocumentLinks;
